import 'phaser'
import 'game'
const Utils = require('./utils.js')
const sleep = m => new Promise(r => setTimeout(r, m));

export default class MapSetup extends Phaser.Scene {

  constructor () {
    super({ key: 'dna' });
  }

  preload () {
    this.width = this.sys.game.canvas.width;
    this.height = this.sys.game.canvas.height;
    this.centerX = this.width / 2;
    this.centerY = this.height / 2;
    this.load.image('machine', 'src/img/sequence/Machine.png');
    this.load.image('cover', 'src/img/sequence/Cover.png');
    this.load.image('flask', 'src/img/sequence/flask.png');
    this.load.image('hand', 'src/img/sequence/Robohand.png');
    this.load.atlas(
      'coverOpen',
      'src/img/sequence/Cover_open.png',
      'src/img/sequence/Cover_open.json'
    );
    this.load.atlas(
      'coverPreOpen',
      'src/img/sequence/Cover_preopen.png',
      'src/img/sequence/Cover_preopen.json'
    );
    this.load.atlas(
      'coverProcess',
      'src/img/sequence/Cover_process.png',
      'src/img/sequence/Cover_process.json'
    );
    
    this.load.atlas(
      'firework',
      'src/img/sequence/firework.png',
      'src/img/sequence/firework.json'
    );
  }

  create () {
    this.pendingDna = 0;
    var frameNames = this.anims.generateFrameNames('coverProcess');
    this.anims.create({ key: 'coverProcess', frames: frameNames, frameRate: 15, repeat: -1, hideOnComplete: false });
    var frameNames = this.anims.generateFrameNames('coverPreOpen');
    this.anims.create({ key: 'coverPreOpen', frames: frameNames, frameRate: 15, repeat: 0, hideOnComplete: false });
    var frameNames = this.anims.generateFrameNames('coverOpen');
    this.anims.create({ key: 'coverOpen', frames: frameNames, frameRate: 15, repeat: 0, hideOnComplete: false });
    var frameNames = this.anims.generateFrameNames('firework');
    this.anims.create({ key: 'firework', frames: frameNames, frameRate: 15, repeat: 0, hideOnComplete: true });

    var machine = this.add.image(this.centerX, this.centerY, 'machine');
    machine.depth = 1;
    this.cover = this.add.image(this.centerX - 64, this.centerY - 15, 'cover');
    this.cover.depth = 2;
    this.coverProcess = this.add.sprite(this.centerX - 64, this.centerY - 15, 'coverProcess');
    this.coverProcess.depth = 3;
    this.coverProcess.visible = false;
    this.coverPreOpen = this.add.sprite(this.centerX - 64, this.centerY - 15, 'coverPreOpen');
    this.coverPreOpen.depth = 3;
    this.coverPreOpen.visible = false;
    this.coverOpen = this.add.sprite(this.centerX - 64, this.centerY - 15, 'coverOpen');
    this.coverOpen.depth = 3;
    this.coverOpen.visible = false;
  }

  sequenceAnimation() {
    this.pendingDna++;
    var hand = this.add.image(this.centerX + 79, this.centerY - 590, 'hand');
    var flask = this.add.image(this.centerX + 79, this.centerY - 350, 'flask');
    flask.depth = 0;

    this.tweens.add({
      targets: hand,
      y: '+=175' ,
      ease: 'Sine.easeInOut',
      duration: 1000,
      yoyo: true,
      onYoyo: () => {
        
      },
      onComplete: () => {
        hand.destroy();
      }
    });

    this.tweens.add({
      targets: flask,
      y: '+=175' ,
      ease: 'Sine.easeInOut',
      duration: 1000,
      onComplete: () => {
        this.pullFlaskDown(flask)
      }
    });
  }

  pullFlaskDown(flask) {
    this.tweens.add({
      targets: flask,
      y: '+=120',
      ease: 'Linear',
      duration: 1000,
      onComplete: () => {
        this.displayProcess();
        flask.destroy()
      }
    });
  }

  displayProcess() {
    this.coverOpen.visible = false;
    this.coverPreOpen.visible = false;
    this.coverProcess.visible = true;
    this.coverProcess.anims.play('coverProcess')
  }

  async displayReward(animal) {
    this.pendingDna--;
    this.coverOpen.visible = false;
    this.coverProcess.visible = false;
    this.coverPreOpen.visible = true;
    this.coverPreOpen.anims.play('coverPreOpen')
    await sleep(300)
    this.coverOpen.visible = true;
    this.coverProcess.visible = false;
    this.coverPreOpen.visible = false;
    this.cover.visible = false;
    this.coverOpen.anims.play('coverOpen')
    this.load.atlas(
      animal.attackImage, 
      'src/img/atlas/animals/' + animal.attackImage + '.png',
      'src/img/atlas/animals/' + animal.attackImage + '.json'
    );

    this.load.once('complete', () => { // JIT loading
      var animalSprite = this.add.sprite(this.centerX - 25, this.centerY, animal.attackImage);
      this.displayFirework()
      this.displayFirework()
      this.displayFirework()
      animalSprite.depth = 3;
      this.tweens.add({
        targets: animalSprite,
        y: '+=300',
        alpha:0,
        scale: 0.25,
        ease: 'Back.easeIn',
        duration: 2000,
        onComplete: () => {
          animalSprite.destroy()
          this.coverOpen.visible = false;
          if (this.pendingDna > 0) {
            this.coverProcess.visible = true;
          } else {
            this.cover.visible = true;
          }
        }
      });
    });
    this.load.start(); 
  }

  displayFirework() {
    var randX = Utils.getRandomInt(-150, 150)
    var randY = Utils.getRandomInt(-150, 150)
    var firework = this.add.sprite(this.centerX + randX, this.centerY + randY, 'firework');
    firework.depth = 4;
    firework.anims.play('firework')

    setTimeout(() => {
      firework.destroy();
    }, 2000)
  }
}
