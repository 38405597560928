/*
* The animal class acts as a Phaser sprite container, data is synced the server
*/

import 'phaser'
import 'fight';
var _ = require('underscore');
const sleep = m => new Promise(r => setTimeout(r, m));

export default class Animal {
  constructor (fight, animalData, container, boss) {
    this.fight = fight;
    this.animalData = animalData;
    this.animalData.boss = boss;
    this.imageName = fight.animalSprite(animalData);
    this.frameNames = this.fight.anims.generateFrameNames(this.imageName);
    this.boss = boss;
    this.container = container;
    this.sprite = this.fight.add.image(0, 0, this.imageName);
    this.container.depth = this.container.y;

    this.buildSprite()
    this.attack = this.attack.bind(this);
    this.setFrame = this.setFrame.bind(this);
    this.attacksAnimation = this.attacksAnimation.bind(this);
    this.hurtAnimation = this.hurtAnimation.bind(this);
    this.defenseAnimation = this.defenseAnimation.bind(this);
    this.bossEntrence = this.bossEntrence.bind(this);
    this.showDamageText = this.showDamageText.bind(this);
    this.deathAnimation = this.deathAnimation.bind(this);
  }

  buildSprite() {
    if (this.boss) {
      this.sprite.name = 'boss';
      this.sprite.setScale(1.1)
    } else {
      this.sprite.name = 'animal';
      this.sprite.flipX = true;
      this.sprite.frameNames = this.fight.anims.generateFrameNames(this.imageName);
      this.sprite.setScale(0.7)
    }
    this.container.add(this.sprite)
  }

  updateHealthbar(data) {
    if (!this.healthbar) {
      return console.log('missing health bar')
    }
    var size = 128;
    this.healthbar.displayWidth =  size - (this.percentageChange(data.healthMax, data.health, size));
  }

  attack(x, y, attackee, damage, attackeeData, bonus) {
    var attacked = false; // Workaround for yoyo called twice
    if (this.animalData.health <= 0) {
      return new Promise(resolve => {
        resolve('resolved')
      });
    }
    return new Promise(resolve => {
      this.attacksAnimation();
      this.fight.tweens.add({
        targets: this.container,
        x: x,
        y: y,
        ease: 'Power3',
        duration: 350,
        yoyo: true,
        onYoyo: () => {
          if (attacked) {return;}
          if (this.boss) {
            //console.log('boss attack')
            attacked = true;
            this.displayAttack(attackee, damage, attackeeData);
          } else {
            //console.log('player attack')
            attacked = true;
            this.displayAttack(attackee, damage, attackeeData);
            if (bonus) {
              attackee.defenseAnimation(bonus)
            }
          }
        },
        onComplete: () => {
          resolve('resolved');
        }
      });
    });
  }

  percentageChange(oldNumber, newNumber, size){
    var decreaseValue = oldNumber - newNumber;

    return (decreaseValue / oldNumber) * size;
  }

  displayAttack(attackee, damage, attackeeData) {
    if (!window.muted) {
      this.fight.sound.play(this.animalData.attackSkill + 'So');
    }
    attackee.animalData.health = attackeeData.health;
    attackee.hurtAnimation();
    attackee.updateHealthbar(attackeeData);
    attackee.showDamageText(damage);
    if (attackee.animalData.health <= 0) {
      attackee.deathAnimation(() => {
        console.log('died ' + attackee.animalData.name)
        this.fight.removeAnimalFromSeats(attackee)
      })
    }
  }

  setFrame(num) {
     this.sprite.setFrame(this.frameNames[num].frame)
  }

  attacksAnimation() {
    this.setFrame(1)
    var x = this.boss ? -125 : 100
    var attack = this.fight.add.sprite(x, -30, this.animalData.attackSkill);
    attack.flipX = this.boss ? false : true;
    this.container.add(attack)
    attack.anims.play(this.animalData.attackSkill)
    setTimeout(() => { attack.destroy(); this.setFrame(0) }, 1000);
  }

  defenseAnimation(defenseSkill) {
    var x = this.boss ? 25 : -5;
    var defense = this.fight.add.sprite(x, -125, defenseSkill)
    this.container.add(defense)
    this.container.sendToBack(defense)
    defense.anims.play(defenseSkill)
    setTimeout(() => { defense.destroy(); }, 1000);
    this.fight.cameras.main.shake(100, 0.01, true)
  }

  hurtAnimation() {
    if (this.animalData.health <= 0) {
      return;
    }

    this.setFrame(2)
    this.fight.tweens.add({
      targets: this.container,
      y: this.container.y - 25,
      ease: 'Sine',
      duration: 200,
      yoyo: true,
      angle: this.boss ? 5 : -5,
      onComplete: () => {
        this.setFrame(0)
      }
    });
  }

  async bossEntrence() {
    if (!window.muted) {
      this.fight.sound.play('flightSo');
    }
    return new Promise(resolve => {
      var tween = this.fight.tweens.add({
        targets: this.sprite,
        y: this.sprite.y + 650,
        ease: 'Power3',
        duration: 500,
        onComplete: () => {
          if (!window.muted) {
            this.fight.sound.play('landingSo');
          }
          this.fight.cameras.main.shake(100, 0.05, true);
          resolve('resolved');
        }
      });
    });
  }

  showDamageText(damage) {
    if (!this.alive()) { return; }
    if (!damage) { return }
    var damageText = this.fight.add.text(this.container.x, this.container.y - 100, '-' + damage, {
      fontFamily: 'Arial', fontSize: 68, color: '#db293a', stroke: '#fff', strokeThinkness: 2, depth: 0
    });

    this.fight.tweens.add({
      targets: damageText,
      y: this.container.y - 400,
      alpha: 0.25,
      ease: 'Sine.easeOut',
      duration: 850,
      onUpdate: () => {
        this.z = this.y;
      },
      onComplete: () => {
        damageText.destroy();
      }
    });
  }

  deathAnimation(callback) {
    this.fight.tweens.add({
      targets: this.container,
      alpha: 0.5,
      ease: 'Power2',
      angle: this.boss ? 90 : -90,
      duration: 500,
      onComplete: () => {
        this.container.removeAll();
        if (callback) { callback(); }
      }
    });
  }

  alive() {
    return this.animalData.health > 0;
  }
}
