import 'phaser'

export default class Preloader extends Phaser.Scene {

  constructor () {
    super({ key: 'preloader' });

  }

  async preload () {
    this.width = this.sys.game.canvas.width;
    this.height = this.sys.game.canvas.height;
    this.centerX = this.width / 2;
    this.centerY = this.height / 2;

    var frameNames = this.anims.generateFrameNames('earth');
    this.anims.create({ key: 'earth', frames: frameNames, frameRate: 15, repeat: 1000, hideOnComplete: false });
    var earth = this.add.sprite(this.centerX, this.centerY, 'earth');
    earth.anims.play('earth')
    var progressBg = this.add.image(this.centerX, this.centerY + 200, 'preloaderBg');
    var progressBar = this.add.image(this.centerX, this.centerY + 200, 'preloaderBar')
    $('#phaser').css('z-index', 100);

    this.load.on('progress', (value) => {
      progressBg.displayWidth = parseInt(300)
      progressBar.displayWidth = parseInt(value * 300)
    });

    this.load.on('complete', () => {
      
    });

    this.load.image('fightbg1', 'src/img/fightbg1.png');
    this.load.image('fightbg2', 'src/img/fightbg2.png');
    this.load.image('fightbg3', 'src/img/fightbg3.png');
    this.load.image('fightbg4', 'src/img/fightbg4.png');
    //this.load.image('fightBtn', 'src/img/fight.png');
    this.load.image('fastForwardBtn', 'src/img/fight/fast_forward.png')
    this.load.image('fleeBtn', 'src/img/fight/flee.png');
    this.load.image('okBtn', 'src/img/fight/ok.png');
    this.load.image('vs', 'src/img/fight/vs.png');
    this.load.image('vsBlue', 'src/img/fight/vs_blue.png');
    this.load.image('vsRed', 'src/img/fight/vs_red.png');
    this.load.image('defeat', 'src/img/fight/defeat.png');
    this.load.image('win', 'src/img/fight/win.png');

    this.load.image('fightcardAir1', 'src/img/cards/fight_cards/fightcard_air_sml_1.png');
    this.load.image('fightcardAir2', 'src/img/cards/fight_cards/fightcard_air_sml_2.png');
    this.load.image('fightcardAir3', 'src/img/cards/fight_cards/fightcard_air_sml_3.png');
    this.load.image('fightcardAir4', 'src/img/cards/fight_cards/fightcard_air_sml_4.png');
    this.load.image('fightcardAir5', 'src/img/cards/fight_cards/fightcard_air_sml_5.png');
    this.load.image('fightcardLand1', 'src/img/cards/fight_cards/fightcard_land_sml_1.png');
    this.load.image('fightcardLand2', 'src/img/cards/fight_cards/fightcard_land_sml_2.png');
    this.load.image('fightcardLand3', 'src/img/cards/fight_cards/fightcard_land_sml_3.png');
    this.load.image('fightcardLand4', 'src/img/cards/fight_cards/fightcard_land_sml_4.png');
    this.load.image('fightcardLand5', 'src/img/cards/fight_cards/fightcard_land_sml_5.png');
    this.load.image('fightcardWater1', 'src/img/cards/fight_cards/fightcard_water_sml_1.png');
    this.load.image('fightcardWater2', 'src/img/cards/fight_cards/fightcard_water_sml_2.png');
    this.load.image('fightcardWater3', 'src/img/cards/fight_cards/fightcard_water_sml_3.png');
    this.load.image('fightcardWater4', 'src/img/cards/fight_cards/fightcard_water_sml_4.png');
    this.load.image('fightcardWater5', 'src/img/cards/fight_cards/fightcard_water_sml_5.png');
    this.load.image('healthBar', 'src/img/cards/fight_cards/health_strip_sml.png');

    this.load.audio('beakSo', 'src/sound/atk_beak.mp3');
    this.load.audio('clawsSo', 'src/sound/atk_claws.mp3');
    this.load.audio('fangsSo', 'src/sound/atk_fangs.mp3');
    this.load.audio('fishtailSo', 'src/sound/atk_fishtail.mp3');
    this.load.audio('hornSo', 'src/sound/atk_horn.mp3');
    this.load.audio('impactSo', 'src/sound/atk_impact.mp3');
    this.load.audio('poisonSo', 'src/sound/atk_poison.mp3');
    this.load.audio('punchSo', 'src/sound/atk_punch.mp3');
    this.load.audio('electroSo', 'src/sound/atk_shock.mp3');
    this.load.audio('tailSo', 'src/sound/atk_tail.mp3');
    this.load.audio('wing_wind_1So', 'src/sound/atk_wing_wind_1.mp3');
    this.load.audio('wing_wind_2So', 'src/sound/atk_wing_wind_2.mp3');
    this.load.audio('bannerSo', 'src/sound/banner.mp3');
    this.load.audio('buttonSo', 'src/sound/button.mp3');
    this.load.audio('clankSo', 'src/sound/clank.mp3');
    this.load.audio('increaseSo', 'src/sound/def_increase.mp3');
    this.load.audio('decreaseSo', 'src/sound/def_decrease.mp3');
    this.load.audio('healSo', 'src/sound/def_heal.mp3');
    this.load.audio('fireballSo', 'src/sound/fireball.mp3');
    this.load.audio('fireworksSo', 'src/sound/fireworks.mp3');
    this.load.audio('flameSo', 'src/sound/flame.mp3');
    this.load.audio('flightSo', 'src/sound/flight.mp3');
    this.load.audio('jingleSo', 'src/sound/jingle.mp3');
    this.load.audio('levelSo', 'src/sound/level.mp3');
    this.load.audio('landingSo', 'src/sound/opponent_landing.mp3');
    this.load.audio('vsbannerSo', 'src/sound/vs_banner.mp3');
    this.load.audio('travelSo', 'src/sound/walking.mp3');

    this.load.json('countries', 'src/countries.json ');
    this.load.json('animals', 'src/animals.json ');
    this.load.json('reverseCountry', 'src/country-reverse-geocoding.json ');

    this.load.image('air1', 'src/img/cards/air/1.png');
    this.load.image('air2', 'src/img/cards/air/2.png');
    this.load.image('air3', 'src/img/cards/air/3.png');
    this.load.image('air4', 'src/img/cards/air/4.png');
    this.load.image('air5', 'src/img/cards/air/5.png');
    this.load.image('land1', 'src/img/cards/land/1.png');
    this.load.image('land2', 'src/img/cards/land/2.png');
    this.load.image('land3', 'src/img/cards/land/3.png');
    this.load.image('land4', 'src/img/cards/land/4.png');
    this.load.image('land5', 'src/img/cards/land/5.png');
    this.load.image('water1', 'src/img/cards/water/1.png');
    this.load.image('water2', 'src/img/cards/water/2.png');
    this.load.image('water3', 'src/img/cards/water/3.png');
    this.load.image('water4', 'src/img/cards/water/4.png');
    this.load.image('water5', 'src/img/cards/water/5.png');

    // Attack animations
    var attackAtlas = [
      'beak', 'claws', 'electro', 'fishtail', 'fangs', 'fishtail', 'horn', 'impact', 'poison',
      'punch', 'tail', 'wing_wind_1', 'wing_wind_2'
    ]
    for (var attack of attackAtlas) {
      this.load.atlas(
        attack,
        'src/img/atlas/attacks/' + attack + '.png',
        'src/img/atlas/attacks/' + attack + '.json'
      );
    }

    // defense animations
    var defenseAtlas = [
      'decrease_accuracy', 'decrease_attack', 'decrease_defense', 'decrease_evasion', 'heal',
      'increase_accuracy', 'increase_attack', 'increase_defense', 'increase_evasion'
    ]
    for (var defense of defenseAtlas) {
      this.load.atlas(
        defense,
        'src/img/atlas/defense/' + defense + '.png',
        'src/img/atlas/defense/' + defense + '.json'
      );
    }
  }

  create() {
    window.countries = this.cache.json.get('countries');
    window.animalData = this.cache.json.get('animals');
    window.reverseCountry = this.cache.json.get('reverseCountry');
    // attacks
    var attackAtlas = [
      'beak', 'claws', 'electro', 'fishtail', 'horn', 'impact', 'fangs', 'poison', 'punch', 'tail',
      'wing_wind_1', 'wing_wind_2'
    ]
    for (var attack of attackAtlas) {
      var frameNames = this.anims.generateFrameNames(attack);
      this.anims.create({ key: attack, frames: frameNames, frameRate: 30, repeat: 1, hideOnComplete: true });
    }

    // defense
    var defenseAtlas = [
      'decrease_accuracy', 'decrease_attack', 'decrease_defense', 'decrease_evasion', 'heal',
      'increase_accuracy', 'increase_attack', 'increase_defense', 'increase_evasion'
    ]
    for (var defense of defenseAtlas) {
      var frameNames = this.anims.generateFrameNames(defense);
      this.anims.create({ key: defense, frames: frameNames, frameRate: 30, repeat: 1, hideOnComplete: true });
    }

    console.log('loading done')
    $('#phaser').css('z-index', 0);
    this.scene.start('game')
  }
}
