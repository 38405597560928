// ---- Attack skills ----

// bonus per every animal with specific nature
const wingsDamage = 10;
const punchDamage = 8;
const fishtailDamage = 12;

// Bonus if no specific nature
const hornDamage = 15;
const tailDamage = 15;

// bonus per every same skill on teammate
const electroDamage = 30;
const clawsDamage = 12;
const fangsDamage = 11;
const beakDamage = 13;

// current HP based
const poisonMaxDamage = 50;
const impactMaxDamage = 20;

module.exports = {
    getAttackSkillBonus: function(animal, boss, teamAnimals) {
        switch (animal.attackSkill) {
            case "punch":
                return this.getPunchAttackBonus(animal, teamAnimals);
            case "fangs":
                return this.getFangsAttackBonus(animal, teamAnimals);     
            case "tail":
                return this.getTailAttackBonus(animal, teamAnimals);
            case "fishtail":
                return this.getFishTailAttackBonus(animal, teamAnimals);
            case "electro":
                return this.getElectroAttackBonus(animal, teamAnimals);                
            case "poison":                
                return this.getPoisonAttackBonus(boss);
            case "beak":
                return this.getBeakAttackBonus(animal, teamAnimals);
            case "claws":
                return this.getClawsAttackBonus(animal, teamAnimals);
            case "impact":
                return this.getImpactAttackBonus(animal);
            case "horn":
                return this.getHornAttackBonus(animal, teamAnimals);
            case "wing_wind_1":
                return this.getWingsAttackBonus(animal, teamAnimals);  
        }
    },

    getPunchAttackBonus: function(animal, teamAnimals) {
        return punchDamage * this.getTeammatesWithNatureCount(animal, teamAnimals, "land");
    },

    getFangsAttackBonus: function(animal, teamAnimals) {
        return fangsDamage * this.getTeammatesWithSkillCount(animal, teamAnimals, "fangs");
    },

    getTailAttackBonus: function(animal, teamAnimals) {
        if (this.getTeammatesWithNatureCount(animal, teamAnimals, "air") === 0) {
            return tailDamage;
        } else {
            return 0;
        }    
    },

    getFishTailAttackBonus: function(animal, teamAnimals) {
        return fishtailDamage * this.getTeammatesWithNatureCount(animal, teamAnimals, "water");
    },

    getElectroAttackBonus: function(animal, teamAnimals) {
        return electroDamage * this.getTeammatesWithSkillCount(animal, teamAnimals, "electro");
    },

    getPoisonAttackBonus: function(boss) {
        return poisonMaxDamage * (1 - boss.health / boss.healthMax);
    },

    getBeakAttackBonus: function(animal, teamAnimals) {
        return beakDamage * this.getTeammatesWithSkillCount(animal, teamAnimals, "beak");
    },

    getClawsAttackBonus: function(animal, teamAnimals) {
        return clawsDamage * this.getTeammatesWithSkillCount(animal, teamAnimals, "claws");
    },

    getImpactAttackBonus: function(animal) {
        return impactMaxDamage * (animal.health / animal.healthMax);
    },

    getHornAttackBonus: function(animal, teamAnimals) {
        if (this.getTeammatesWithNatureCount(animal, teamAnimals, "land") === 0) {
            return hornDamage;
        } else {
            return 0;
        }        
    },

    getWingsAttackBonus: function(animal, teamAnimals) {
        return wingsDamage * this.getTeammatesWithNatureCount(animal, teamAnimals, "air");
    },

    getTeammatesWithSkillCount: function (animal, teamAnimals, skillName) {
        let teammatesWithSkill = 0;
        teamAnimals.forEach(teammate => {
            if (teammate === animal) return;
            if (teammate.attackSkill === skillName) {
                teammatesWithSkill++;
            }
        });

        return teammatesWithSkill;
    },

    getTeammatesWithNatureCount: function (animal, teamAnimals, natureName) {
        let teammatesWithNature = 0;
        teamAnimals.forEach(teammate => {
            if (teammate === animal) return;
            if (teammate.nature === natureName) {
                teammatesWithNature++;
            }
        });

        return teammatesWithNature;
    },

    getAttackSkillTitle : function(attackSkillName) {
        switch (attackSkillName) {
            case "punch":
                return "Punch: Deals " + punchDamage + " additional damage for every other land ally.";
            case "fangs":
                return  "Fangs: Deals " + fangsDamage + " additional damage for every other ally with Fangs.";
            case "tail":
                return "Tail: Deals " + tailDamage + " additional damage if you have no other air ally."
            case "fishtail":
                return "Fish Tail: Deals " + fishtailDamage + " additional damage for every other water ally."
            case "electro":
                return "Electro: Deals " + electroDamage + " additional damage for every other ally with Electro.";               
            case "poison":                
                return "Poison: The less health the boss has, the more additional damage this skill deals (" + poisonMaxDamage / 2  +" on 50% HP)."; 
            case "beak":
                return "Beak: Deals " + beakDamage + " additional damage for every other ally with Beak.";
            case "claws":
                return "Claws: Deals " + clawsDamage + " additional damage for every other ally with Claws.";
            case "impact":
                return "Impact: The more HP this animal has, the more additional damage this skill deals (" + impactMaxDamage + " at 100%).";
            case "horn":
                return "Horn: Deals " + hornDamage + " additional damage if you have no other land ally."
            case "wing_wind_1":
                return "Wings: Deals " + wingsDamage + " additional damage for every other air ally." 
        }
    },

    getSupportSkillTitle: function(supportSkillName) {
        switch (supportSkillName) {
            case "heal":
                return "Heal: Every other ally recovers 10% max hp.";
            case "decrease_attack":
                return "Decrease Attack: Decreases the attack of a boss to 70%";
            case "decrease_defense":                
                return  "Decrease Defense: Decreases the defense of a boss to 70%";
            case "decrease_evasion":
                return "Decrease Evasion: Decreases the evasion of a boss to 70%";
            case "decrease_accuracy":
                return "Decrease Accuracy: Decreases the accuracy of a boss to 70%";
            case "increase_attack":
                return "Increase Attack: Increases attack of every other ally by 15";
            case "increase_defense":
                return "Increase Defense: Increases defense of every other ally by 17";
            case "increase_evasion":
                return "Increase Evasion: Increases evasion of every other ally by 15";
            case "increase_accuracy":
                return "Increase Accuracy: Increases accuracy of every other ally by 17";
            
        }
    }
}