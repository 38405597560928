$('.tutorial-next').click(clickNext);

const tutorialSteps = 5;
let currentTutorialId = 0;

function clickNext() {
    currentTutorialId++;
    hideAllTutorials();
    showCurrentTutorial();
    
}

function hideAllTutorials() {
    for (let index = 0; index < tutorialSteps; index++) {
        $('.tutorial-step-' + index).addClass("d-none");        
    }   
}

function showCurrentTutorial() {
    $('.tutorial-step-' + currentTutorialId).removeClass("d-none"); 

    if (tutorialSteps === currentTutorialId) {
        $('.tutorial-next').addClass("d-none"); 
        $('.tutorial-close' ).removeClass("d-none"); 
    }
}

module.exports = {
    displayTutorial: function() {
    $('.tutorial-modal').on('show.bs.modal', (e) => {
      var modal = $(e.target)
    });

    $('.tutorial-modal').on('hidden.bs.modal', () => {
      window.socket.emit('tutorial');
    })

    $('.tutorial-modal').modal('show');
  }
}
