/*
* Collection of useful but rarely changed and encapsulated functions. Shared with client and server
*/

const Utils = {
  level: function(experience) {
    return Math.round(Math.sqrt((experience + 2) * 10) * 0.12)
  },

  nextLevelExp: function(experience) {
    var currentLevel = this.level(experience)
    var nextLevel = currentLevel
    var tempExp = this.clone(experience)
    while (currentLevel == nextLevel) {
      tempExp++
      nextLevel = this.level(tempExp)
    }
    return tempExp
  },

  rollRandomChance: function (chance){
    return Math.random() <= chance;
  },

  getZeroToRandomInt: function (max) {
    var rand = Math.floor(Math.random() * (max +1));
    return rand
  },

  getRandomInt: function(min, max) {
    var rand = Math.floor(Math.random() * (max - min + 1)) + min;
    if (rand === 0) { rand = this.getRandomInt(min, max); }
    return rand
  },

  getSeperationRand: function(min, max, limit) {
    var rand = Math.random() * (max - min + 1) + min;
    if (rand === 0) { rand = this.getSeperationRand(min, max); }
    if (Math.abs(rand) < limit) { rand = this.getSeperationRand(min, max); }
    return rand
  },

  travelRandInt: function(min, max) {
    var rand = Math.random() * (max - min + 1) + min;
    if (rand === 0) { rand = this.travelRandInt(min, max); }
    if (Math.abs(rand) < 0.5) { rand = this.travelRandInt(min, max); }
    return rand
  },

  getRandomFloat: function(min, max) {
    var rand = Math.random() * (max - min + 1) + min;
    if (rand > -0.50 && rand < 0.50 ) { rand = this.getRandomFloat(min, max); }
    return rand
  },

  getRandomString: function() {
    var text = "";
    var possible = "mnqrstuvwxyz";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  },

  findAnimalById: function(id, playerAnimals, worldAnimals) {
    for(var i = 0; i < playerAnimals.length; i++) {
      if (playerAnimals[i].id == id) {
        return playerAnimals[i]
      }
    }

    for(var i = 0; i < worldAnimals.length; i++) {
      if (worldAnimals[i].id == id) {
        return worldAnimals[i]
      }
    }
  },

  findDnaById: function(id, playerDna) {
    for(var i = 0; i < playerDna.length; i++) {
      if (playerDna[i].id == id) {
        return playerDna[i]
      }
    }
  },

  findAnimalDataById: function(id, animalData) {
    for(var i = 0; i < animalData.length; i++) {
      if (animalData[i].wildAnimalId == id) {
        return animalData[i]
      }
    }
  },

  deleteAnimalById: function(id, playerAnimals, worldAnimals) {
    for(var i = 0; i < playerAnimals.length; i++) {
      if (playerAnimals[i].id == id) {
        playerAnimals.splice(i, 1);
      }
    }

    for(var i = 0; i < worldAnimals.length; i++) {
      if (worldAnimals[i].id == id) {
        worldAnimals.splice(i, 1);
      }
    }
  },

  deleteDnaById: function(id) {
    for(var i = 0; i < playerDna.length; i++) {
      if (playerDna[i].id == id) {
        playerDna.splice(i, 1);
      }
    }
  },

  playerOwnsAnimal: function(id) {
    for (var i = 0; i < playerAnimals.length; i++) {
      if (playerAnimals[i].id == id) {
        return true;
      }
    }

    return false;
  },

  findAnimalsInCountry: function(id) {
    var animals = [];
    for(var i = 0; i < worldAnimals.length; i++) {
      if (worldAnimals[i].inCountry == id) {
        animals.push(worldAnimals[i])
      }
    }
    return animals
  },

  findAnimalDataByName: function(name, animalData) {
    for(var animal of animalData) {
      if (animal.name == name) {
        return animal
      }
    }
  },

  distance: function(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
  },

  countryById: function(id, countries) {
    for(var i = 0; i < countries.length; i++) {
      if (countries[i].cca2 == id) {
        return this.clone(countries[i])
      }
    }
  },

  dnaById: function(id) {
    for(var i = 0; i < playerDna.length; i++) {
      if (playerDna[i].id == id) {
        return playerDna[i]
      }
    }
  },

  point_in_polygon: function(polygon, point) {
    var nvert = polygon.length;
    var c = false;
    var i = 0;
    var j = 0;
    for(i = 0, j = nvert-1; i < nvert; j = i++) {
      if( ((polygon[i][1] > point[1]) != (polygon[j][1] > point[1])) && 
         (point[0] < (polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1]) / (polygon[j][1] - polygon[i][1]) + polygon[i][0]) ) {
        c = !c;
      }
    }
    return c;
  },

  getCountry: function(lat, lng) {
    if(typeof lat !== 'number' || typeof lng!== 'number') {
      return false;
    }

    var point = [lng, lat];
    var i = 0;
    var found = false;
    do {
      var country = reverseCountry[i];
      if(country.geometry.type === 'Polygon') {
        found = this.point_in_polygon(country.geometry.coordinates[0], point);
      }
      else if(country.geometry.type === 'MultiPolygon') {
        var j = 0;
        do {
          found = this.point_in_polygon(country.geometry.coordinates[j][0], point);
          j++;
        } while (j < country.geometry.coordinates.length && !found);
      }
      i++;
    } while (i < reverseCountry.length && !found);

    if(found) {
      return {
        code: reverseCountry[i-1].id,
        name: reverseCountry[i-1].properties.name
      };
    }
    else {
      return false;
    }
  },

  camelToTitle: function(str, separator){
    separator = typeof separator === 'undefined' ? ' ' : separator;

    return this.toTitleCase(str
          .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
          .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2'))
  },

  itemNameToTitle: function(name) {
    return this.toTitleCase(name.split('_').join(' '));
  },

  toTitleCase: function(str) {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  // Prevent stacking
  seperateAnimalImage: function(mapImage, countries, playerAnimals, worldAnimals) {
    var tryCount = 0
    
    var country = this.countryById(mapImage.inCountry, countries)
    //console.log(mapImage.inCountry, countries)
    var geocode;
    var inUS = mapImage.inCountry == 'US'
    var inCA = mapImage.inCountry == 'CA'
    var inGL = mapImage.inCountry == 'GL'
    var inRU = mapImage.inCountry == 'RU'
    var inCN = mapImage.inCountry == 'CN'
    var inBR = mapImage.inCountry == 'BR'
    var inAU = mapImage.inCountry == 'AU'

    while (this.mapImageStacked(mapImage)) {
      
      if (inUS || inCA || inGL || inRU || inCN || inBR || inAU) {
        var tempLat = country.latlng[0] + this.getSeperationRand(-6, 6, 5)
        var tempLong = country.latlng[1] + this.getSeperationRand(-10, 10, 5);
      } else {
        var tempLat = country.latlng[0] + this.getSeperationRand(-4, 4, 0.5)
        var tempLong = country.latlng[1] +this.getSeperationRand(-5, 5, 0.5);
      }
      geocode = this.getCountry(tempLat, tempLong, this.mapImageStacked(mapImage))

      tryCount++;
      if (tryCount > 50) {
        break;
      }

      if (!geocode) {
        continue;
      }

      if (geocode.code == country.cca3) {
        mapImage.latitude = tempLat
        mapImage.longitude = tempLong
        break;
      }
    }

    var animalId = mapImage.id.replace('p', '').replace('w', '');
    var animal = this.findAnimalById(animalId, playerAnimals, worldAnimals)
    animal.latitude = mapImage.latitude;
    animal.longitude = mapImage.longitude;
  },

  mapImageStacked: function(mapImage) {
    for (var i = 0; i < window.map.dataProvider.images.length; i++) {
      var testImage = window.map.dataProvider.images[i]
      if (mapImage.id != testImage.id && mapImage.id != 'circle') {
        if (Math.abs(mapImage.latitude - testImage.latitude) < 2) {
          return true
        }
        if (Math.abs(mapImage.longitude - testImage.longitude) < 2) {
          return true
        }
      }
    }

    return false
  },

  randomWestNpc: function(worldAnimals) {
    var randAnimal = this.getZeroToRandomInt(worldAnimals.length - 1);
    if (worldAnimals[randAnimal].longitude <= 10) {
      return randAnimal;
    } else {
      return this.randomWestNpc(worldAnimals);
    }
  },

  randomEastNpc: function(worldAnimals) {
    var randAnimal = this.getZeroToRandomInt(worldAnimals.length - 1);
    if (worldAnimals[randAnimal].longitude > 10) {
      return randAnimal;
    } else {
      return this.randomEastNpc(worldAnimals);
    }
  },

  hideAllWorldAnimals: function() {
    for (var i = 0; i < worldAnimals.length; i++ ) {
      window.map.hideGroup(worldAnimals[i].country);
    }
  },

  randomCountry: function() {
    var country = this.clone(countries[this.getRandomInt(0, countries.length - 1)])
    return country
  },

  clone: function(obj) {
    return JSON.parse(JSON.stringify(obj))
  },

  generateAnimal: function(dna, animalData, countries) {
    var temp = {
      id: '',
      name: '',
      health: 100,
      rarity: 1,
      accessory1: null,
      accessory2: null,
      accessory3: null,
      generation: 0,
      level: 1,
      experience: 1,
      attack: 0,
      defense: 0,
      accuracy: 0,
      evasion: 0,
      nature: '',
      power: 0,
      attackSkill: '',
      defenseSkill: '',
      traveling: false,
      boss: false,
      effectiveness: 1
    }

    if (!dna) {
      var randAnimal = animalData.animals[this.getRandomInt(1, animalData.animals.length) - 1]
      var randCountryId = randAnimal.start[this.getRandomInt(1, randAnimal.start.length) - 1]
      var country = this.countryById(randCountryId, countries)
      temp.image = randAnimal.image
      temp.name = randAnimal.name
      temp.xp = this.getRandomInt(1, 100)
      temp.attack = this.getRandomInt(10, 150)
      temp.defense = this.getRandomInt(10, 150)
      temp.accuracy = this.getRandomInt(10, 90)
      temp.evasion = this.getRandomInt(10, 90)
      temp.power = this.getRandomInt(10, 90)
      temp.attackSkill = randAnimal.attackSkill;
      temp.defenseSkill = randAnimal.defenseSkill;
      temp.nature = randAnimal.nature;
      temp.effectiveness = this.getRandomInt(10, 90);
      temp.wildAnimalId = randAnimal.wildAnimalId
    }

    temp.rarity = this.getRandomInt(1, 3)
    temp.latitude = country.lat
    temp.longitude = country.long;
    temp.inCountry = country.cca2;
    temp.id = this.getRandomString();
    return temp
  }
};

try {
  module.exports = Utils
} catch(e) { } // not node
