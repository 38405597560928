var Web3 = require('web3');
import Cookies from 'js-cookie'
const Utils = require('./utils.js')
var loggedIn = false;

export default class Wallet {
  static connect(noMetamask) {
    if (window.ethereum) {
      this.web3 = new Web3(ethereum);
    } else if (window.web3) {
      this.web3 = new Web3(web3.currentProvider);
    } else {
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
      noMetamask();
    }

    if (this.web3) {
      this.web3.eth.getAccounts((err, acc) => {
        this.address = acc[0].toLowerCase();
      });
    }
  }

  static isLoggedIn() {
    return loggedIn;
  }

  static updateWalletDisplay() {
    setInterval(() => {
      if (!this.web3 || !window.socket) {
        $('.network-icon-green').hide();
        $('.network-icon-red').show();
        $('.network-icon-red').attr('title', 'disconnected, try refreshing')
        $('.bird').show();
      } else {
        this.web3.eth.net.getNetworkType().then((network) => {
          this.network = network;
          // testnet
          if (window.location.href.search('test') != -1 || window.location.href.search('localhost') != -1) {
            if (network == 'ropsten') {
              $('.network-icon-red').hide();
              $('.network-icon-green').show();
              $('.network-icon-green').attr('title', 'connected to ropsten network')
            } else {
              $('.network-icon-green').hide();
              $('.network-icon-red').show();
              $('.network-icon-red').attr('title', 'unknown network, try ropsten')
            }
          } else { // main
            if (network == 'main') {
              $('.network-icon-red').hide();
              $('.network-icon-green').show();
              $('.network-icon-green').attr('title', 'connected to main network')
            } else {
              $('.network-icon-green').hide();
              $('.network-icon-red').show();
              $('.network-icon-red').attr('title', 'unknown network, try main')
            }
          }

          this.web3.eth.getAccounts((err, acc) => {
            if (this.address != acc[0].toLowerCase()) {
              location.reload();
            }
          });
        });

        Wallet.getBalance(function(amount) {
          $('.money').html('Ξ' + amount)
        });
      }
    }, 2000);
  }

  static getBalance(callback) {
    this.web3.eth.getAccounts((err, acc) => {
      this.web3.eth.getBalance(acc[0]).then((error, result) => {
        if (!error && result) {
          console.log(result)
          callback(this.web3.fromWei(result, "ether" ) );
        };
      });
    });
  }

  static getChallenge(socket) {
    if (this.address) {
      var session = Cookies.get('session');
      socket.emit('auth/challenge', this.address, session);
    } else {
      console.log('address not found')
    }
  }

  static solveChallenge(socket, challenge) {
    var from = this.address;
    const params = [challenge, from];
    const method = 'eth_signTypedData';

    web3.currentProvider.sendAsync({
      method,
      params,
      from
    }, async (err, result) => {
      if (err) { return console.error(err); }
      if (result.error) { return console.error(result.error); }
      var signature = result.result;

      socket.emit('auth/response', signature);
    });
  }

  static login(data, contractBundle, session, callback) {
    if (data === this.address.toLowerCase()) {
      loggedIn = true;
      this.v1 = new this.web3.eth.Contract(contractBundle.v1Abi, contractBundle.v1Address);
      this.v2 = new this.web3.eth.Contract(contractBundle.v2Abi, contractBundle.v2Address);
      this.market = new this.web3.eth.Contract(contractBundle.marketAbi, contractBundle.marketAddress);
      this.airdrop = new this.web3.eth.Contract(contractBundle.airdropAbi, contractBundle.airdropAddress);
      this.v1Market = new this.web3.eth.Contract(contractBundle.v1Market, contractBundle.v1Market)
      Cookies.set('session', session, { expires: 7 });
      callback();
    } else {
      loggedIn = false;
      console.log('login failed', data, this.address)
    }
  }

  static async getPlayerDna() {
    var syncData = await this.v2.methods.getSyncCost().call();
    this.syncCost = syncData.toNumber()
    var spawnData = await this.v2.methods.getSpawnCost().call();
    this.spawnCost = spawnData.toString()

    window.playerDna = [];
    console.log('fetching dna')
    // All DNAs the player have on V1 contract
    var dna = await this.v1.methods.getPlayerDnas(this.address)
      .call({ from: this.address })
    for (var sample of dna) {
      var data = await this.v1.methods.getDna(sample).call({ from: this.address });
      var sequenced = await this.v2.methods.isWithdrawnFromContract(this.address, data.animalId)
        .call({ from: this.address })
      var animalData = Utils.clone(Utils.findAnimalDataById(data.animalId, window.animalData.animals));
      if (!sequenced) {
        animalData.id = data.id.toNumber();
        animalData.owner = data.owner;
        animalData.type = 'wild';
        animalData.effectiveness = data.effectiveness;
        window.playerDna.push(animalData);
      }
    }

    var dna = await this.airdrop.methods.getAddressDnaIds(this.address.toLowerCase())
      .call({ from: this.address })
    for (var sample of dna) {
      var data = await this.airdrop.methods.getDna(sample).call({ from: this.address });
      var sequencedAirdrop = await this.v2.methods.isWithdrawnFromRepository(this.address, data.animalId)
        .call({ from: this.address })
      var animalData = Utils.clone(Utils.findAnimalDataById(data.animalId, window.animalData.animals));
      if (!sequencedAirdrop) {
        animalData.id = data.id.toNumber();
        animalData.owner = data.owner;
        animalData.type = 'airdrop';
        animalData.effectiveness = data.effectiveness;
        window.playerDna.push(animalData);
      }
    }

    window.dnaLoaded = true;
    console.log('dna loaded')
  }

  static sequenceDNA(dnaIds, sent, mined) {
    var dna = Utils.findDnaById(dnaIds[0], playerDna)

    if (dna.type == 'wild') {
      this.v2.methods.sequenceContractDna(dna.wildAnimalId, dnaIds)
        .send({ from: this.address })
        .once('transactionHash', (hash) => {
          sent();
        })
        .once('confirmation', function(confNumber, receipt) {
          mined();
        })
    } else {
      this.v2.methods.sequenceRepositoryDna(dna.wildAnimalId, dnaIds)
        .send({ from: this.address })
        .once('transactionHash', (hash) => {
          sent();
        })
        .once('confirmation', function(confNumber, receipt) {
          mined();
        })
      }
    
  }


  static createAuction(id, startPrice, endPrice, duration, sent, mined) {
    if (!startPrice || !endPrice || !duration) {
      return;
    }

    var startPrice = this.web3.utils.toWei(startPrice.toString())
    var endPrice = this.web3.utils.toWei(endPrice)

    this.v2.methods.createAuction(id, startPrice, endPrice, duration)
      .send({ from: this.address })
      .once('transactionHash', (hash) => {
        sent(id);
      })
      .once('receipt', (receipt) => {
        mined(id);
      })
  }

  static cancelAuction(id, sent, mined) {
    this.market.methods.cancelAuction(id)
      .send({ from: this.address })
      .once('transactionHash', (hash) => {
        sent(id);
      })
      .once('receipt', (receipt) => {
        mined(id);
      })
  }

  static buyAuction(id, value, sent, confirmed, mined) {
    this.market.methods.buyAuction(id)
      .send({ from: this.address, value: value })
      .once('transactionHash', (hash) => {
        sent(id);
      })
      .once('confirmation', (confNumber, receipt) => {
          confirmed(id)
      })
      .once('receipt', () => {
        mined(id);
      })
  }

  static async marketCount() {
    return await this.market.methods.getAuctionsCount().call()
  }

  static async loadMarketCard(index) {
    var item = await this.market.methods.getAuctionByIndex(index).call();
    if (Wallet.fromWei(item.endPrice.toString()) > Wallet.fromWei(item.startPrice.toString())) {
      item.currentPrice = item.currentPrice.add('1000000000000000'); //0.001
    }
    var animal = await Wallet.getAnimal(item.tokenId.toNumber());
    try {
      var fullAnimal = Object.assign(animal, item);
      return fullAnimal;
    } catch (e) {
      console.log('problem with market item', item, animal, e)
    }  
  }

  static async loadAlphaMarket() {
    var count = await this.v1.methods.getAnimalsCount().call();
    var alphaMarketAnimals = []

    for (var i = 0; i < count; i++) {
      var item = await this.v1.methods.getAnimal(i).call();
      try {
        if (item.currentValue == 0) {
          var animal = await Wallet.getAlpha(item.id.toNumber());
          alphaMarketAnimals.push(animal)
        }
      } catch (e) {
        console.log('problem with alpha item', item, animal, e)
      }
    }
    console.log(alphaMarketAnimals)
    return alphaMarketAnimals
  }

  static async getAnimal(id) { // only useful for market as country comes from db
    var animalContract = await this.v2.methods.getAnimal(id).call();
    var wildAnimalId = animalContract.wildAnimalId.toNumber();
    var animalData = Utils.findAnimalDataById(wildAnimalId, window.animalData.animals);

    if (!animalData) {
      return console.log('Missing animalid ' + wildAnimalId);
    }
    var newAnimal = Utils.clone(animalData);
    var country = Utils.countryById(newAnimal.start[0], window.countries)
    newAnimal.latitude = country.latlng[0]
    newAnimal.longitude = country.latlng[1]
    newAnimal.inCountry = newAnimal.start[0];
    newAnimal.id = id;
    newAnimal.network = this.network;
    newAnimal.effectiveness = animalContract.effectiveness;
    newAnimal.owner = animalContract.owner;
    newAnimal.wildAnimalId = animalContract.wildAnimalId.toNumber();
    newAnimal.accessories = animalContract.accessories;
    newAnimal.xp = animalContract.xp.toNumber();
    return newAnimal;
  }

  static async getAlpha(id) { // only useful for market as country comes from db
    var animalContract = await this.v1.methods.getAnimal(id).call();
    var name = this.web3.utils.hexToAscii(animalContract.name).replace(/\0.*$/g,'');
    console.log(animalContract)
    var animalContractV2 = await this.v2.methods.getAnimal(id).call();
    var animalData = Utils.findAnimalDataByName(name, window.animalData.animals);
    if (!animalData) {
      console.log('Missing animalid ' + name)
      return;
    }
    var newAnimal = Utils.clone(animalData);

    newAnimal.inCountry = newAnimal.start[0];
    var country = Utils.countryById(newAnimal.inCountry, countries)
    newAnimal.latitude = country.latlng[0]
    newAnimal.longitude = country.latlng[1]
    newAnimal.id = id;
    newAnimal.boss = true;
    newAnimal.wildAnimalId = animalData.wildAnimalId;
    newAnimal.owner = animalContract.owner;
    newAnimal.effectiveness = 100;
    newAnimal.alpha = true;
    newAnimal.unsyncedXp =  0;
    newAnimal.currentValue = animalContract.currentValue.toString();
    newAnimal.targetValue = animalContract.targetValue.toString();
    newAnimal.network = this.network;

    return newAnimal
  }

  static syncXp(id, xpToAdd, sig, nonce, sent, mined) {
    console.log('syncXp', id, xpToAdd, sig, nonce)
    this.v2.methods.addXp(id, xpToAdd, nonce, sig.v, sig.r, sig.s)
      .send({ from: this.address, value: this.syncCost })
      .once('transactionHash', (hash) => {
        sent(id);
      })
      .once('confirmation', (confNumber, receipt) => {
        mined(id);
      })
  }

  static syncGems(gemsToAdd, sig, nonce, sent, confirmed, mined) {
    console.log('syncGems')
    this.v2.methods.requestGems(gemsToAdd, nonce, sig.v, sig.r, sig.s)
      .send({ from: this.address, value: this.syncCost })
      .once('transactionHash', (hash) => {
        sent();
      })
      .once('confirmation', (confNumber, receipt) => {
        confirmed();
      })
      .once('receipt', () => {
        mined();
      })
  }

  static syncNewSpawn(animalData, effectiveness, timestamp, sig, nonce, sent, mined) {
    console.log('syncNewSpawn')

    this.v2.methods.spawnOffspring(
      animalData.wildAnimalId, effectiveness, timestamp, nonce, sig.v, sig.r, sig.s
    )
    .send({ from: this.address, value: this.spawnCost })
    .once('transactionHash', (hash) => {
      sent();
    })
    .once('receipt', (confNumber, receipt) => {
      mined();
    })
  }

  static useAccessory(animalId, slotId, itemId, nonce, sig, sent, mined) {
    console.log('use accessory');

    this.v2.methods.addAccessories(
      animalId, slotId, itemId, nonce, sig.v, sig.r, sig.s
    )
    .send({ from: this.address, value: this.syncCost })
    .once('confirmation', (confNumber, receipt) => {
      sent();
    })
    .once('receipt', () => {
      mined();
    })
  }

  static async getGems() {
    var data = await this.v2.methods.getPlayerGems(this.address).call();
    return data.toNumber();
  }

  static fromWei(wei) {

    return this.web3.utils.fromWei(wei, 'ether')
  }
}
