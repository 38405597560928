import 'phaser'
import 'game'
const Utils = require('./utils.js')

export default class MapSetup extends Phaser.Scene {

  constructor () {
    super({ key: 'mapSetup' });

    window.map = AmCharts.makeChart( "map", {
      type: "map",
      theme: "light",
      projection: "miller",
      backgroundZoomsToTop: true,
      preventDragOut: true,
      backgroundAlpha: 1,
      autoResize: true,
      backgroundAlpha: 0,
      mouseWheelZoomEnabled: true,
      linesAboveImages: false,
      centerMap: true,
      zoomDuration: 0,
      dragMap: false,
      zoomControl: {
        zoomControlEnabled: false,
        homeButtonEnabled: true,
        buttonSize: 43,
        buttonBorderColor: '#fff',
        buttonFillColor: '#2a397c',
        buttonColorHover: '#2980e1',
        buttonRollOverColor: '#2980e1',
        buttonIconColor: '#fff'
      },
      dataProvider: {
        map: "mapVar",
        //mapURL: 'src/worldLow.svg',
        getAreasFromMap: true,
        rollOverScale: 1,
        selectable: true,
        bringForwardOnHover: true,
        zoomLevel: 1,
        zoomLongitude: 6.8770,
        zoomLatitude: 31.3070,
      },

      areasSettings: {
        color: '#F8FAF0',
        rollOverColor: '#F8FAF0',
        selectedColor: '#F8FAF0',
        selectedOutlineColor: "#EEF2DD",
        rollOverOutlineColor: '#EEF2DD',
        outlineColor: "#D2D6C4",
        selectedOutlineThickness: 0.5,
        rollOverOutlineAlpha: 1,
        outlineAlpha: 1,
        outlineThickness: 0.5,
        alpha: 1,
        bringForwardOnHover: false,
        autoZoom: false,
        selectable: true
      },

      linesSettings: {
        color: "#028be9",
        alpha: 1,
        thinkness: 100,
        dashLength: 2,
        bringForwardOnHover: false,
        arc: 0,
        mouseEnabled: false
      },

      imageSettings: {
        alpha: 1,
        bringForwardOnHover: true,
        centered: true
      },
      balloon: {
        enabled: false
      }
    });

    // main init
    window.map.addListener( "init", () => {

    });

    window.map.zoomOut = function() {
      window.map.dataProvider.zoomLevel = 1;
      window.map.dataProvider.zoomLatitude = 31.3070;
      window.map.dataProvider.zoomLongitude = 6.8770;
      window.map.validateData()
    }

  }

  preload () {

  }

  create () {

  }

}
