import 'phaser'
import Preload from 'preloader'
import Animal from 'animal'
import Wallet from 'wallet'
const Utils = require('./utils.js')
var _ = require('underscore');
const sleep = m => new Promise(r => setTimeout(r, m));

export default class Fight extends Phaser.Scene {

  constructor () {
    super({ key: 'fight' });
  }

  preload () {
    this.width = this.sys.game.canvas.width;
    this.height = this.sys.game.canvas.height;
    this.centerX = this.width / 2;
    this.centerY = this.height / 2;
    this.seats = [];
    this.availableAnimals = _.clone(window.playerAnimals);
    this.attackInProgress = false;
    this.vsBlueContainer = this.add.container(this.centerX - 1700, this.centerY)
    this.vsRedContainer = this.add.container(this.centerX + 700, this.centerY)
    this.fastForward = false;

    for (var animal of window.animalData.animals) {
      this.load.atlas( // Animal attack sprite sheets
        animal.attackImage,
        'src/img/atlas/animals/' + animal.attackImage + '.png',
        'src/img/atlas/animals/' + animal.attackImage + '.json'
      );

      this.load.image( // Animals
        animal.image,
        'src/img/animals/' + animal.image
      );
    }


    for (var acc of window.accessoryData) {
      this.load.image(acc.image, 'src/img/cards/accessories/' + acc.image);
    }

    this.load.atlas(
      'firework',
      'src/img/sequence/firework.png',
      'src/img/sequence/firework.json'
    );
  }

  create () {
    this.cameras.main.setBounds(0, 0, this.width, this.height);
    this.input.on('gameobjectdown', this.handleClicks, this);
    var frameNames = this.anims.generateFrameNames('firework');
    this.anims.create({ key: 'firework', frames: frameNames, frameRate: 15, repeat: 0, hideOnComplete: true });
    if (window.gameAnimal.nature == 'land') {
      if (Math.random() < 0.5) {
        this.bg = this.add.image(this.centerX, this.centerY, 'fightbg1');
      } else {
        this.bg = this.add.image(this.centerX, this.centerY, 'fightbg4');
      }
    } else if (window.gameAnimal.nature == 'water') {
      this.bg = this.add.image(this.centerX, this.centerY, 'fightbg2');
    } else {
      this.bg = this.add.image(this.centerX, this.centerY, 'fightbg3');
    }

    //this.bossSquare = this.add.image(this.centerX + 355, this.centerY - 50, 'bossSquare');
    //this.redSquare1 = this.add.image(this.centerX + 10, this.centerY + 10, 'redSquare');
    //this.redSquare2 = this.add.image(this.centerX - 160, this.centerY + 10, 'redSquare');
    //this.redSquare3 = this.add.image(this.centerX - 330, this.centerY + 10, 'redSquare');
    //this.blueSquare1 = this.add.image(this.centerX - 80, this.centerY + 110, 'blueSquare');
    //this.blueSquare2 = this.add.image(this.centerX - 250, this.centerY + 110, 'blueSquare');

    this.fastForwardBtn = this.add.image(this.centerX + 790, this.centerY - 430, 'fastForwardBtn');
    this.fastForwardBtn.setInteractive({ useHandCursor: true })
    this.fastForwardBtn.name = 'fastForward';

    this.bossSeat = this.add.container(this.centerX + 250, this.centerY - 74);
    this.boss = new Animal(this, window.gameAnimal, this.bossSeat, true);
    this.bossFightCard(this.boss);

    this.seat1 = this.add.container(this.centerX - 125, this.centerY - 100);
    this.seat2 = this.add.container(this.centerX - 150, this.centerY + 50);
    this.seat3 = this.add.container(this.centerX - 350, this.centerY - 50);
    this.showPhaser();

    this.buildAnimalList()
    this.attackInProgress = true;
    for (var id of window.fightersList) {
      var animalData = Utils.findAnimalById(parseInt(id), playerAnimals, [])
      var container = this['seat' + (this.seats.length + 1)];
      
      var animal = new Animal(this, animalData, container, false);
      this.seats.push(animal);
      this.fightCard(animal);
    }

    this.displayIntro();
  }

  displayFirework() {
    var randX = Utils.getRandomInt(-550, 550)
    var randY = Utils.getRandomInt(-200, 200)
    var firework = this.add.sprite(this.centerX + randX, this.centerY + randY, 'firework');
    firework.depth = 801;
    firework.anims.play('firework')

    setTimeout(() => {
      firework.destroy();
    }, 2000)
  }

  removeAnimalFromSeats(animal) {
    if (animal.boss) {
      return;
    }

    var i = 0;
    for (var seat of this.seats) {
      if (seat.animalData && seat.animalData.id == animal.animalData.id) {
        this.seats.splice(i, 1);
        break;
      }
      i++;
    }
  }

  findAnimalSeat(animalData) {
    if (!animalData) { return }
    if (animalData.boss) {
      return this.boss;
    }

    for (var seat of this.seats) {
      if (seat.animalData && seat.animalData.id == animalData.id) {
        return seat;
      }
    }
  }

  animalSprite(animal) {
    var aniData = Utils.findAnimalDataById(animal.wildAnimalId, window.animalData.animals)
    if (!aniData) { console.log(animal) }
    if (aniData) { return aniData.attackImage }
  }

  buildAnimalList() {  
    var count = 0;
    var imageName = this.animalSprite(window.gameAnimal);
    var introSprite = this.add.image(420 , 20, imageName);
    introSprite.angle = -12;
    introSprite.depth = 801;
    introSprite.setScale(1.1);
    this.vsRedContainer.add(introSprite)

    for (var id of window.fightersList) {
      var animal = Utils.findAnimalById(id, playerAnimals, [])
      if (animal.boss) {
        continue;
      }
      var imageName = this.animalSprite(animal);
      var introSprite = this.add.image(((count * -1) * 200) + 550 , (count * 20.3) - 110, imageName);
      introSprite.flipX = true;
      introSprite.angle = -12;
      introSprite.depth = 801;
      introSprite.setScale(1.1);
      this.vsBlueContainer.add(introSprite)
      count++;
    }
  }

  async handleClicks(pointer, object) {
    if (object.name == 'ok') {
      this.fastForward = true;
      this.hidePhaser();
      window.socket.emit('unsyncedGemCount');
    }

    if (object.name == 'fastForward') {
      this.fastForward = true;
    }

    if (!window.muted) {
      this.sound.play('buttonSo');
    }
  }

  async triggerFightSequence() {
    window.socket.emit('fight', {
      boss: this.boss.animalData.id,
      animals: _.map(this.seats, (seat, key) => {
        return this.seats[key].animalData.id
      })
    })
  }

  async displayAttackSequence(rounds, accessory, newSpawn) {
    console.log('fight started ' + rounds.length)
    console.log(rounds)
    window.gameAnimal.health = 500; // just to reset
    var i = rounds.length
    loop1:
    while (i--) { // incoming data is reversed, to enable skipping
      var sequence = rounds[i];

      for (var seq of sequence) {
        if (this.fastForward && i != 0) {
          continue;
        }
        var attackerData = seq.attacker;
        var attackeeData = seq.attackee;
        var attackee = this.findAnimalSeat(attackeeData);
        var attacker = this.findAnimalSeat(attackerData);
        if (!attacker.boss) {
          if (seq.damage) {
            if (seq.bonus) {
              if (seq.bonus.search('decrease') == -1) {

              if (!window.muted) {
                this.sound.play('increaseSo');
                this.sound.play('fireballSo');
              }
              attacker.defenseAnimation(seq.bonus)

              if (seq.bonus == 'heal') {
                if (!window.muted) {
                  this.sound.play('healSo');
                }
                attacker.health = attackerData.health;
                attacker.updateHealthbar(attackerData)
              }
              await sleep(1000)

            }

            }
            await attacker.attack(
              this.boss.container.x - 75,
              this.boss.container.y - 50,
              attackee,
              seq.damage,
              attackeeData
            );
          } else {
            if (seq.bonus.search('decrease') > -1) {
              if (!window.muted) {
                this.sound.play('decreaseSo');
                this.sound.play('fireballSo');
              }
              await attacker.attack(
                this.boss.container.x - 75,
                this.boss.container.y - 50,
                attackee,
                0,
                attackeeData,
                seq.bonus
              );
            } else {
              if (!window.muted) {
                this.sound.play('increaseSo');
                this.sound.play('fireballSo');
              }
              attacker.defenseAnimation(seq.bonus)
              await sleep(1000)
            }
          }
        } else {
          if (seq.bonus) {
            if (!window.muted) {
              this.sound.play('increaseSo');
              this.sound.play('fireballSo');
            }
            attacker.defenseAnimation(seq.bonus)
            await sleep(800)
          }
          await attacker.attack(
            attackee.container.x,
            attackee.container.y - 50,
            attackee,
            seq.damage,
            attackeeData
          );
        }
        await sleep(200)
      }
      await sleep(300)
      if (this.fastForward && i != 0) {
        this.fastForwardBtn.destroy()
        i = 1;
      }
      
    }
    if (this.fastForward && i != 0) {
      this.quickDeath(rounds);
    }
    this.checkFightWon(rounds, accessory, newSpawn);
    await sleep(1000);
  }

  quickDeath(rounds) {
    var i = rounds.length;
    loop1:
    while (i--) { // incoming data is reversed, to enable skipping
      var sequence = rounds[i];
      if (i == 0) {
        break;
      }
      for (var seq of sequence) {
        var attackeeData = seq.attackee;
        var attackee = this.findAnimalSeat(attackeeData);
        if (attackeeData.health <= 0 && !attackeeData.boss) {
          if (!attackee) {
            return;
          }
          attackee.updateHealthbar(attackeeData)
          attackee.deathAnimation(() => {
            if (!attackee) {
              return;
            }
            console.log('died ' + attackee.animalData.name)
            this.removeAnimalFromSeats(attackee)
          })
        }
      }
    }
  }

  checkFightWon(rounds, accessory, newSpawn) {
    if (this.boss.animalData.health <= 0) {
      this.quickDeath(rounds);
      this.displayEnd(true, accessory, newSpawn)
    } else {
      this.displayEnd(false)
    }
  }

  displayIntro() {
    var mask = this.add.image(this.centerX, this.centerY, 'blackMask');
    mask.displayWidth = 1920;
    mask.displayHeight = 1080;
    mask.alpha = 1;
    mask.depth = 800;
    this.vsBlueContainer.depth = 800;
    var vsBlue = this.add.image(0, 0, 'vsBlue');
    vsBlue.depth = 0;
    this.vsBlueContainer.add(vsBlue)
    this.vsBlueContainer.sendToBack(vsBlue)
    this.vsRedContainer.depth = 800;
    var vsRed = this.add.image(700, 0, 'vsRed');
    vsRed.depth = 0;
    this.vsRedContainer.add(vsRed);
    this.vsRedContainer.sendToBack(vsRed)
    var vs = this.add.image(this.centerX + 200, this.centerY, 'vs');
    vs.depth = 800;
    vs.alpha = 0;
    this.boss.sprite.y -= 650;
    if (!window.muted) {
      this.sound.play('vsbannerSo');
    }
    var maskTween = this.tweens.add({
      targets: mask,
      alpha: 0.5,
      duration: 450,
      onComplete: () => {

        this.tweens.add({
          targets: this.vsBlueContainer,
          x: this.centerX - 350,
          duration: 500,
          ease: 'Sine.easeIn',
          onComplete: async () => {
            await sleep(500)
            await this.fadeAndDestroy(mask)
            await this.fadeAndDestroy(this.vsBlueContainer)
            await this.fadeAndDestroy(this.vsRedContainer)
            await this.fadeAndDestroy(vs)
            await this.boss.bossEntrence();
            await sleep(500)
            this.triggerFightSequence();
          }
        });

        this.tweens.add({
          targets: this.vsRedContainer,
          x: this.centerX,
          duration: 500,
          ease: 'Sine.easeIn',
        });

        this.tweens.add({
          targets: vs,
          alpha: 1,
          duration: 500,
          ease: 'Sine.easeIn',
        });
      },
    });
  }

  fadeAndDestroy(sprite) {
    return new Promise(resolve => {
      var maskTween = this.tweens.add({
        targets: sprite,
        alpha: 0,
        duration: 250,
        onComplete: () => {
          resolve('resolved');
        }
      });
    });
  }

  async displayEnd(won, accessory, newSpawn) {
    var mask = this.add.image(this.centerX, this.centerY, 'blackMask');
    var count = 0;
    mask.displayWidth = 1920;
    mask.displayHeight = 1080;
    mask.alpha = 0;
    mask.depth = 800;
    this.tweens.add({
      targets: mask,
      alpha: 0.5,
      duration: 750,
      onComplete: async () => {
        if (won) {
          var background = this.add.image(this.centerX, this.centerY, 'win');
          background.depth = 800;
          var gemText = this.add.text(this.centerX , this.centerY + 180, '+ ' + (this.boss.animalData.rarity + 1) + ' Gems', {
            fontFamily: 'Arial', fontSize: 44, color: '#fff', stroke: '#000', strokeThinkness: 2
          });
          gemText.x -= gemText.width / 2
          gemText.depth = 801;
        } else {
          var background = this.add.image(this.centerX, this.centerY, 'defeat');
          background.depth = 800;
        }
        for (var id of window.fightersList) {
          var animal = Utils.findAnimalById(id, playerAnimals, [])
          var imageName = this.animalSprite(animal);
          var sprite = this.add.image(this.centerX - 525 + (count * 500), this.centerY, imageName);
          sprite.depth = 801;
          var xpText = this.add.text(this.centerX - 525 + (count * 500), this.centerY - 150, '+1 XP', {
            fontFamily: 'Arial', fontSize: 36, color: '#fff', stroke: '#000', strokeThinkness: 2
          });
          xpText.depth = 801;
          count++;
        }

        if (accessory) {
          var acc = this.add.image(this.centerX + 250, this.centerY + 210, accessory.image)
          acc.depth = 801;
          acc.setScale(2)
          var accText = this.add.text(this.centerX + 290, this.centerY + 190, accessory.name + ' card accessory', {
            fontFamily: 'Arial', fontSize: 32, color: '#fff', stroke: '#000', strokeThinkness: 2
          });
          accText.depth = 801;
        }

        if (newSpawn) {
          var spawnText = this.add.text(this.centerX - 490, this.centerY + 190, 'Chance to spawn', {
            fontFamily: 'Arial', fontSize: 32, color: '#fff', stroke: '#000', strokeThinkness: 2
          });
          spawnText.depth = 801;
        }
        
        var okBtn = this.add.image(this.centerX, this.centerY + 400, 'okBtn');
        okBtn.setInteractive({ useHandCursor: true })
        okBtn.depth = 800;
        okBtn.name = 'ok'

        if (won) {
          if (!window.muted) {
            this.sound.play('fireworksSo');
          }
          this.displayFirework();
          await sleep(Utils.getRandomInt(0, 700))
          this.displayFirework();
          await sleep(Utils.getRandomInt(0, 700))
          this.displayFirework();
          await sleep(Utils.getRandomInt(0, 700))
          this.displayFirework();
          await sleep(Utils.getRandomInt(0, 700))
          this.displayFirework();
        } 
      }
    });
  }

  fightCard(animal) {
    var x = ((this.seats.length - 1) * 190) + 115
    var fightCardContainer = this.add.container(x, this.centerY + 430);
    var nature = animal.animalData.nature.replace(/^./, str => str.toUpperCase())
    var cardNumber = Math.round(animal.animalData.effectiveness / 2 / 10);
    var card = this.add.image(0, 0, 'fightcard' + nature + cardNumber.toString());
    fightCardContainer.add(card)
    var healthbar = this.add.image(-65, 53, 'healthBar');
    healthbar.setOrigin(0)
    animal.healthbar = healthbar;
    fightCardContainer.add(healthbar);
    var image = this.add.image(0, -40, animal.animalData.attackImage);
    image.setScale(0.5);
    image.flipX = true;
    fightCardContainer.add(image);
  }

  bossFightCard(animal) {
    var fightCardContainer = this.add.container(1795, this.centerY + 430);
    var nature = animal.animalData.nature.replace(/^./, str => str.toUpperCase())
    var cardNumber = Math.round(animal.animalData.effectiveness / 2 / 10);
    var card = this.add.image(0, 0, 'fightcard' + nature + cardNumber.toString());
    fightCardContainer.add(card)
    var healthbar = this.add.image(-65, 53, 'healthBar');
    healthbar.setOrigin(0)
    animal.healthbar = healthbar;
    fightCardContainer.add(healthbar);
    var image = this.add.image(0, -40, animal.animalData.attackImage);
    image.setScale(0.5);
    fightCardContainer.add(image);
  }

  bossAlive() {
    return this.boss.animalData.health > 0
  }

  animalsAlive() {
    return this.seats.length !== 0
  }

  animalById(id) {
    for (var animal of this.availableAnimals) {
      if (animal.id == id) {
        return animal;
      }
    }
  }

  searchContainer(container, name) {
    for (var child of container.list) {
      if (child.name == name) {
        return child;
      }
    }
  }

  showPhaser() {
    $('#phaser').css('z-index', 100);
  }

  hidePhaser() {
    window.map.zoomOut();
    window.socket.emit('playerAnimals');
    $('#phaser').css('z-index', 0);
  }
}
