window.npcNum = 30;
window.playerDna = [];
window.playerAnimals = [];
window.worldAnimals = [];
window.selectedCard = '';
window.selectedAttackee = '';
window.selectedCountry = '';
window.reverseCountry;
window.natures = ['land', 'water', 'sky']
window.money = 0.25;
window.animalData;
window.muted = false;
window.accessories;

var inventory = [{ name: 'blue_gem', count: 1 }];
var gameStarted = false;
window.dnaLoaded;

import 'phaser'
import Preload from 'preloader'
import Animal from 'animal'
import Wallet from 'wallet'
import io from 'socket.io-client';


const Utils = require('./utils.js')
const SkillUtils = require('./skill_utils.js');
const Tutorial = require('./tutorial.js');

var _ = require('underscore');
const sleep = m => new Promise(r => setTimeout(r, m));

export default class Game extends Phaser.Scene {

  constructor() {
    super({ key: 'game' });
    this.hideAllMenus = this.hideAllMenus.bind(this);  
  }

  async preload() {
    if (gameStarted) { return; }
    window.map.addListener( "clickMapObject", (e) => { this.clickMapObject(e) });

    window.map.addListener('homeButtonClicked', function(e) {
      window.map.zoomOut();
    });

    Wallet.connect(() => {
      $(document).ready(() => {
        $('.metamask-modal').modal({
          backdrop: 'static', keyboard: false
        }).modal('show');
      });
    });
    if (window.ethereum) {
      try {
        await ethereum.enable();
      } catch (error) {
        // User denied account access...
      }
    }
    Wallet.updateWalletDisplay();

    setInterval(() => { // Main timer
      if (playerAnimals) {
        for(var i = 0; i < playerAnimals.length; i++) {
          this.updateCard(playerAnimals[i].id)
        }
      }
      
      for(var i = 0; i < worldAnimals.length; i++) {
        this.updateCard(worldAnimals[i].id)
      }

    }, 1000)

    setInterval(async () => {
     this.updateGemCount();
    }, 180000);

    // workaround since this is loaded twice, once in the head and once by webpack & phaser
    if (!window.socket) {
      var socket = io(window.location.href, {
        reconnection: false
      });
      window.socket = socket;
      socket.on('connect', async () => {
        console.log('Connected, starting game')
      });
      socket.on('disconnect', () => {
        window.socket = null;
      });
      socket.on('animalsData', (animalsJson) => {
        window.animalData = animalsJson;
        Wallet.getChallenge(socket);
        this.loadAnimalImages();
      })
      socket.on('auth/challenge', (challenge) => {
        Wallet.solveChallenge(socket, challenge);
      });
      socket.on('auth/result', async (data, contractBundle, session, version, accessoryData, tutorial) => {
        if (!tutorial) {
          Tutorial.displayTutorial();
        }
        window.accessoryData = accessoryData;
        $('.version').html(version)
        Wallet.login(data, contractBundle, session, async () => {
          console.log('logged in with version ' + version);
          this.updateGemCount();
          await Wallet.getPlayerDna();
          this.rebuildDnaDeck();
          if (!window.marketType) {
            this.rebuildMarket();
          }
        });
      });
      socket.on('worldAnimals', (data) => {
        console.log('recieved ' + data.length + ' bosses', data)
        window.worldAnimals = data;
        this.displayBosses()
      });
      socket.on('playerAnimals', (data) => {
        if (!data.length) { return; }
        if (window.playerAnimals.length != data.length) {
          window.playerAnimals = data;
          this.displayPlayerAnimals();
        } else {
          window.playerAnimals = data;
        }

        this.rebuildDeck();        

        console.log('Recevied ' + playerAnimals.length + ' cards')
      });
      socket.on('attackSequence', (rounds, accessory, newSpawn) => {
        window.game.scene.keys.fight.displayAttackSequence(rounds, accessory, newSpawn);
      });
      socket.on('travel', (success) => {
        if (!success) { return console.log('travel restricted'); }
        this.setupTravel();
      });
      socket.on('syncXp', (id, xpToAdd, sig, nonce) => {
        //console.log(id, xpToAdd, sig, nonce)
        Wallet.syncXp(id, xpToAdd, sig, nonce, () => {

        }, () => {
          if (!window.muted) { this.sound.play('jingleSo'); }
          socket.emit('playerAnimals');
        });
      });
      socket.on('syncGems', (gemsToAdd, sig, nonce) => {
        //console.log(gemToAdd, sig, nonce)
        Wallet.syncGems(gemsToAdd, sig, nonce, () => {

        }, () => {
          socket.emit('unsyncedGemCount');
        }, () => {
          if (!window.muted) { this.sound.play('jingleSo'); }
          socket.emit('unsyncedGemCount');
        });
      });
      socket.on('syncNewSpawn', (randomWildId, effectiveness, timestamp, sig, nonce) => {
        // TODO: new a pre menu to explain syncing https://git-repos.webentertainment-limited.com/games/cryptoserval/v2/-/issues/75
        console.log('new spawn', randomWildId, effectiveness, timestamp, sig, nonce, Wallet.spawnCost);
        var animalData = Utils.findAnimalDataById(randomWildId, window.animalData.animals)
        this.displaySpawnReward(animalData, effectiveness, timestamp, sig, nonce);
      });
      socket.on('unusedAccessories', (accessories) => {
        console.log('accessories', accessories)
        window.accessories = accessories;
      });
      socket.on('unsyncedGemCount', (count, alphaGems) => {
        $('.gem-sync-btn').html(count + ' unsynced gems').show();
        if (alphaGems) { $('.alpha-gems').html(alphaGems + ' alpha gems earned').show(); }
        if (count.constructor === Object || count === 0) {
          console.log('unsyncedGems', count, alphaGems)
          $('.gem-sync-btn').hide();
          $('.alpha-gems').hide();
        }
      });
      socket.on('useAccessory', (animalId, slotId, itemId, nonce, sig) => {
        Wallet.useAccessory(animalId, slotId, itemId, nonce, sig, () => {
          if (!muted) { this.sound.play('jingleSo'); }
          window.socket.emit('unusedAccessories')
          this.openInventory('accessory1');
        }, () => {
          window.socket.emit('playerAnimals');
          this.openInventory('accessory1');
        })
      })
      socket.on('earlyBirdRefresh', () => {
        setTimeout(() => {
          console.log('🐦 too fast')
          location.reload();
        }, 1000);
      });
    }
  }

  create() {
    if (gameStarted) { return; }
    gameStarted = true;
    $(() => {
      window.addEventListener('resize', () => {
        var dist = window.map.kilometersToPixels(10000);
        for (var image of window.map.dataProvider.images) {
          if (image.id != 'circle') {
            continue;
          }

          image.width = dist;
          image.height = dist;
        }
      });

      $('.player-deck-wrap').overlayScrollbars({
        autoUpdate: true,
        className: 'os-theme-thick-light '
      });

      $('.market-wrap').overlayScrollbars({
        autoUpdate: true,
        className: 'os-theme-thick-light '
      });

      $('.dna-list-wrap').overlayScrollbars({
        autoUpdate: true,
        className: 'os-theme-thick-light '
      });

      $('.sequence-list-wrap').overlayScrollbars({
        autoUpdate: true,
        className: 'os-theme-thick-light '
      });

      $('.nearby-list-wrap').overlayScrollbars({
        autoUpdate: true,
        className: 'os-theme-thick-light '
      });

      this.setupJquery();
    });
  }

  setupJquery() {
    $('.metamask').click(() => {
      if (!muted) { this.sound.play('buttonSo'); }
      var win = window.open('https://metamask.io/', '_blank');
      win.focus();
    });
    $('.mute-btn').click(() => {
      if (muted) {
        muted = false
        $('.mute-btn').html('<img src="src/img/main_icons/not_muted.png" class="main-btn-icon">')
        this.sound.play('buttonSo');
      } else {
        muted = true;
        $('.mute-btn').html('<img src="src/img/main_icons/muted.png" class="main-btn-icon">')
      }
    })

    $('.map-btn').click(() => {
      if (!muted) { this.sound.play('buttonSo'); }
      this.hideAllMenus();
    })

    $('.market-btn').click(() => {
      if (!muted) { this.sound.play('buttonSo'); }

      if ($('.market-wrap').is(':visible')) {
        this.hideAllMenus();
      } else {
        this.hideAllMenus();
        $('.market-btn').addClass('active');
        this.rebuildMarket()
        $('.market-wrap').toggle()
      }
    })

    $('.dna-btn').click(() => {
      if (!muted) { this.sound.play('buttonSo'); }

      if ($('.dna-card-deck').is(':visible')) {
        this.hideAllMenus();
      } else {
        this.hideAllMenus();
        $('.dna-btn').addClass('active');
        this.rebuildDnaDeck()
        $('.dna-card-deck').show()
      }
    })

    $('.deck-btn').click(() => {
      if (!muted) { this.sound.play('buttonSo'); }

      if ($('.player-deck-wrap').is(':visible')) {
        this.hideAllMenus();
      } else {
        this.hideAllMenus();
        $('.deck-btn').addClass('active');
        clearTimeout(this.cardsTimer);
        this.cardsTimer = setTimeout(() => {
          window.socket.emit('playerAnimals');
        }, 2000);
        $('.player-deck-wrap').show()
      }
    })


    $('.gem-sync-btn').click(() => {
      if (!muted) { this.sound.play('buttonSo'); }
      this.openSyncWarning(() => {
        window.socket.emit('syncGems')
      });
    })

    $('.dna-list').on('click', '.card', (e) => {
      if ($('.sequence-list').find('.card').length > 0) {
        if (!muted) { this.sound.play('clankSo');  }
        return;
      } else {
        if (!muted) { this.sound.play('buttonSo');  }
      }

      var id = $(e.currentTarget).attr('class').split(' ')[1];
      var dna = Utils.findDnaById(id, playerDna)

      for (var preDna of playerDna) {
        if (preDna.type == dna.type && preDna.wildAnimalId == dna.wildAnimalId) {
          var card = $('.dna-list').find('.' + preDna.id);
          card.remove();
          $('.sequence-list').append(card)
        }
      }
    });

    $('.nearby-list').on('click', '.card', (e) => {
      if ($('.fighters').find('.animal').length == 3) {
        if (!muted) { this.sound.play('clankSo'); }
        return;
      } else {
        if (!muted) { this.sound.play('buttonSo'); }
      }
      var card = $(e.currentTarget);
      var id = card.data('id');
      var animal = Utils.findAnimalById(id, window.playerAnimals, [])

      if (animal.fightingCooldown > Math.floor(new Date().getTime() / 1000)) {
        if (!muted) { this.sound.play('clankSo'); }
        return;
      }

      if (!$('.fighters').find('.animal').length) { // first animal
        this.createFightAnimal(animal, 50, -100)
        this.createStatCard(animal, 1)
      } else if ($('.fighters').find('.animal').length == 1 ) {
        this.createFightAnimal(animal, 0, 0)
        this.createStatCard(animal, 2)
      } else if ($('.fighters').find('.animal').length == 2 ) {
        this.createFightAnimal(animal, -50, -100)
        this.createStatCard(animal, 3)
      }
      card.remove();
    });

    $('.sequence-list').on('click', '.card', function(e) {
      if (!muted) { this.sound.play('buttonSo'); }

      var cards = $('.sequence-list').find('.card')

      cards.each(function( key, card ) {
        card.remove();
        $('.dna-list').prepend(card)
      });
    });

    $('.sequence-btn').click( (e) => {
      if (!muted) { this.sound.play('buttonSo'); }
      var list = []
      var cards = $('.sequence-list').find('.card');
      cards.each(function( key, card ) {
        var id = $(this).attr('class').split(' ')[1];
        list.push(id)
      });

      this.sequence(list)
    }); 

    $('.inventory').on('click', '.item-equipt-btn', (e) => {
      if (!muted) { this.sound.play('buttonSo'); }
      var card = $(e.target).parent();
      var id = card.data('id');
      var name = card.find('.name').html();
      var animal = Utils.findAnimalById(window.selectedCard, playerAnimals, worldAnimals);
      this.openSyncWarning(() => {
        window.socket.emit('useAccessory', animal, id);
      })
    });   

    $('.player-card-deck').on('click', '.sell-btn', (e) => {
      if (!muted) { this.sound.play('buttonSo'); }
      var card = $(e.target).closest('.card');
      var id = card.data('id');
      if (selectedCard == id) {
        selectedCard = null;
      }
      this.openSellMenu(id);
    });

    $('.btn-spawn-animal').click(() => {
      var data = window.spawnInfo;
      Wallet.syncNewSpawn(data.animalData, data.effectiveness, data.timestamp, data.sig, data.nonce, () => {
        if (!muted) { this.sound.play('levelSo'); }
        $('.spawn-reward-modal').modal('hide')
      }, () => {
        if (!muted) { this.sound.play('jingleSo'); }
        window.socket.emit('playerAnimals')
      });
    })

    $('.market-card-deck').on('click', '.buy-btn', async (e) => {
      if (!muted) { this.sound.play('buttonSo'); }
      var card = $(e.target).closest('.card');
      var id = card.data('id');
      for (var animal of window.marketAnimals) {
        if (animal.id == id) {
          Wallet.buyAuction(id, animal.currentPrice, () => {

          }, () => {
            if (!muted) { this.sound.play('jingleSo'); }
            this.rebuildMarket()
          }, () => {
            window.socket.emit('playerAnimals');
            
          })
          break;
        }
      }
    });

    $('.market-card-deck').on('click', '.cancel-sell-btn', async (e) => {
      if (!muted) { this.sound.play('buttonSo'); }
      var card = $(e.target).closest('.card');
      var id = card.data('id');
      Wallet.cancelAuction(id, (id) => {

      }, (id) => {
        window.socket.emit('playerAnimals');
        this.rebuildMarket()
      })
    });

    $('#card-overlay').on('click', '.travel-btn', (e) => {
      if (!muted) { this.sound.play('buttonSo'); }
      if (!selectedCard) {
        window.alert('No card selected in card deck')
      }
      var country = Utils.findAnimalById(window.selectedAttackee, playerAnimals, worldAnimals).inCountry
      window.socket.emit('travel', selectedCard, country)
      e.preventDefault();
    });

    $('#card-overlay').on('click', '.fight-btn', (e) => {
      if (!muted) { this.sound.play('buttonSo'); }
      this.hideAllMenus()
      this.displayPreFight();
      e.preventDefault();
    });

    $('.pre-fight-menu').on('click', '.fight-btn', (e) => {
      if ($('.fighters').find('.animal').length == 0) {
        if (!muted) { this.sound.play('clankSo'); }
        return;
      } else {
        if (!muted) { this.sound.play('buttonSo'); }
      }

      if (!window.gameAnimal.boss) {
        if (!muted) { this.sound.play('clankSo'); }
        alert('Boss has changed')
      }

      this.hideAllMenus()
      var fighters = $('.fighters');
      var list = [];
      fighters.find('img').each(function( key, img ) {
        var id = $(img).attr('id');
        list.push(id)
      })
      window.fightersList = list;
      console.log(fightersList)

      window.game.scene.start('fight');
      e.preventDefault();
    });

    $('.pre-fight-menu').on('click', '.leave-btn', (e) => {
      if (!muted) { this.sound.play('buttonSo'); }
      this.hideAllMenus()
      e.preventDefault();
    });

    $('.player-card-deck').on('click', '.card', (e) => {
      if ($(e.target).hasClass('sell-btn')) {
        return;
      }

      if (!muted) {
        this.sound.play('buttonSo');
      }

      $('.player-card-deck').find('.cardbg').removeClass('selected-card')
      selectedCard = $(e.currentTarget).data('id');
      $(e.currentTarget).find('.cardbg').addClass('selected-card');
      var animal = Utils.findAnimalById(selectedCard, playerAnimals, worldAnimals)
      window.map.zoomToLongLat(3, animal.longitude, animal.latitude, false)
    })

    $('.confirm-sell-btn').click((e) => {
      var modal = $('.sell-modal')
      var animalId = modal.data('animalId');
      var startPrice = modal.find('.start').val();
      var endPrice = modal.find('.end').val();
      var days = parseInt(modal.find('.duration').val());
      var duration = days * 24 * 60 * 60
      Wallet.createAuction(animalId, startPrice, endPrice, duration, (id) => {
        modal.modal('hide')
      }, (id) => {
        console.log('sell started')
        setTimeout(() => {
          window.socket.emit('playerAnimals');
          this.rebuildMarket();
        }, 5000)
      })
    })
  }

  async updateGemCount() {
    var gems = await Wallet.getGems()
    $('.gem-count').html(gems + ' gems')
    window.socket.emit('unsyncedGemCount');
  }

  createFightAnimal(animal, x, y) {
    var image = $(new Image());
    image.attr('src', 'src/img/animals/' + animal.image);
    image.attr('width', '100px');
    image.attr('height', 'auto');
    image.addClass('animal');
    image.attr('id', animal.id);
    $('.fighters').append(image).fadeIn();
  }

  createStatCard(animal, num) {

    if (num == 1 || num == 3) {
      var name = animal.attackSkill.replace('_', '');
      var attackImg = '<img src="src/img/cards/icons/attacks/' + animal.attackSkill + '.png">';
      var attackName = '<h3 class="text-light text-capitalize">' + attackImg + ' ' + name + '</h3>';
      var attackStatImg = '<img src="src/img/cards/icons/stats/attack.png">';
      var attackStat =  attackStatImg + ' ' + animal.attack + ' Attack';
      var accuracyStatImg = '<img src="src/img/cards/icons/stats/accuracy.png">';
      var accuracyStat = accuracyStatImg + ' ' + animal.accuracy + ' Accuracy';
      var stats = '<p class="text-light text-capitalize">' + attackStat + ' ' + accuracyStat + '</p>';
      $('.pre-fight-menu').find('.stats').append(
        
        '<div class="stat">' + attackName + stats + '</div>'
      )
    } else {
      var name = animal.defenseSkill.replace('_', ' ')
      var defenseImg = '<img src="src/img/cards/icons/defense/' + animal.defenseSkill + '.png">';
      var defenseName = '<h3 class="text-light text-capitalize">' + defenseImg + ' ' + name + '</h3>';
      var defenseStatImg = '<img src="src/img/cards/icons/stats/defense.png">';
      var defenseStat =  defenseStatImg + ' ' + animal.defense + ' Defense';
      var evasionStatImg = '<img src="src/img/cards/icons/stats/evasion.png">';
      var evasionStat = evasionStatImg + ' ' + animal.evasion + ' Evasion';
      var stats = '<p class="text-light text-capitalize">' + defenseStat + ' ' + evasionStat + '</p>';
      $('.pre-fight-menu').find('.stats').append(
        '<div class="stat">' + defenseName + stats + '</div>'
      )
    }
    
  }

  rebuildDeck() {
    $('.player-card-deck').html('');
    for (var i = 0; i < window.playerAnimals.length; i++) {
      var card = this.createCard(window.playerAnimals[i])
      card.css('cursor', 'pointer')
      card.find('.sell-btn').show()
      if (selectedCard == window.playerAnimals[i].id) {
        card.find('.cardbg').addClass('selected-card')
      }
      $('.player-card-deck').append(card)
    }
  }

  async rebuildMarket() {
    $('.market-card-deck').html('');
    window.marketType = 'wild';
    var count = await Wallet.marketCount();
    console.log('building market, found ' + count);
    window.marketAnimals = [];

    for (var i = 0; i < count; i++) {
      var animal = await Wallet.loadMarketCard(i);
      marketAnimals.push(animal);

      var card = this.createCard(animal)
      card.css('cursor', 'pointer')
      if (animal.tokenOwner.toLowerCase() == Wallet.address) {
        card.find('.cancel-sell-btn').show()
      } else {
        card.find('.buy-btn').show()
      }
      $('.market-card-deck').append(card)
    }
  }

  rebuildDnaDeck() {
    $('.dna-list').html('');
    $('.sequence-list').html('')
    for (var dna of playerDna) {
      var card = this.createDnaCard(dna.id, dna)
      $('.dna-list').append(card)
    }

    if (window.dnaLoaded && playerDna.length === 0) {
      $('.dna-btn').hide()
    }
  }

  hideAllMenus() {
    $('.dna-card-deck').hide()
    $('.player-deck-wrap').hide()
    $('.market-wrap').hide()
    $('#card-overlay').hide()
    $('.inventory').hide()
    $('.pre-fight-menu').hide();
    $('.market-btn').removeClass('active');
    $('.dna-btn').removeClass('active');
    $('.deck-btn').removeClass('active');
    $('.wallet-btn').removeClass('active')

  }

  async clickMapObject(e) {
    this.hideAllMenus();

    if (e.mapObject.objectType == 'MapImage') {
      if (e.mapObject.id.search('w') != -1) { // world Animal
        var animalId = e.mapObject.id.replace('w', '');
        var animal = Utils.findAnimalById(animalId, [], worldAnimals)
      } else { // player animal
        var animalId = e.mapObject.id.replace('p', '');
        var animal = Utils.findAnimalById(animalId, playerAnimals, [])
      }

      if (Utils.findAnimalById(e.mapObject.id.replace('p', ''), playerAnimals, [])) {
        selectedCard = animalId;
      } else {
        selectedAttackee = animalId
      }

      if (!animal) {
        return console.log('clicked circle')
      }

      if (animal.boss) {
        window.gameAnimal = animal;
      }

      window.map.zoomToLongLat(2, animal.longitude, animal.latitude, false)
      var card = this.createCard(animal);
      this.updateCard(e.mapObject.id)
      selectedCountry = e.mapObject.inCountry;
      var country = Utils.countryById(animal.inCountry, countries)
      $('#card-overlay').html('').append(card)

        if (animal.boss) {
          var traveler = Utils.findAnimalById(selectedCard, playerAnimals, []);
          if (!traveler) {
            return;
          }
          if (!traveler.travelingCooldown || traveler.travelingCooldown <= Math.floor(new Date().getTime() / 1000)) {
            $('#card-overlay').append(
            '<button class="travel-btn btn btn-light float-right" style="white-space: nowrap;">' +
            '<img src="src/img/main_icons/travel.png"> Travel to ' + 
            country.name.common + '</button> '
          )
          }
          $('#card-overlay').append(
           '<button class="fight-btn btn btn-light float-right" style="white-space: nowrap; margin-top: 2px;" ' +
           'data-id="' + animal.id + '"><img src="src/img/main_icons/fight.png"> Fight</button> '
          )
        }
        
        $('#card-overlay').show();

      $('#card-overlay').find('.card').css('margin-left', -20)
    }

    if (window.map.selectedObject.cname == 'MapArea') {
      console.log(e.mapObject.id)
    }

    if (window.map.selectedObject.cname == 'MapData') {
      this.hideAllMenus();
    }
  }

  setupTravel() {
    var playerAnimal = Utils.findAnimalById(window.selectedCard, playerAnimals, worldAnimals);
    var gameAnimal = Utils.findAnimalById(window.selectedAttackee, playerAnimals, worldAnimals);
    var newLat = gameAnimal.latitude + Utils.travelRandInt(-1.2, 1.2);
    var newLong = gameAnimal.longitude + Utils.travelRandInt(-1.2, 1.2);
    var dist = Utils.distance(playerAnimal.latitude, playerAnimal.longitude, newLat, newLong, 'k');
    var lineId = Utils.getRandomString()
    if (playerAnimal.traveling) {
      return;
    }

    playerAnimal.traveling = true;

    window.map.dataProvider.lines.push({
      id: lineId,
      latitudes: [ playerAnimal.latitude, newLat ],
      longitudes: [ playerAnimal.longitude, newLong ]
    })

    playerAnimal.latitude = newLat;
    playerAnimal.longitude = newLong;
    playerAnimal.inCountry = selectedCountry;

    window.map.zoomOut();

    var fin = function() {
      
    }

    if (!muted) {
      this.sound.play('travelSo'); 
    }

    // animation
    var playerImage = window.map.getObjectById('p' + selectedCard);
    playerImage.animateAlongLine = true;
    playerImage.animateAlong(lineId, 10) 

    var openAttackHandler = function(e2) {
      playerAnimal.traveling = false;
      for (var key in window.map.dataProvider.lines) {
        if (window.map.dataProvider.lines[key].id == lineId) {
          window.map.dataProvider.lines.splice(key, 1)
          break;
        }
      }

      playerImage.events.animationEnd = []
      window.map.validateData()
    };

    playerImage.addListener('animationEnd', openAttackHandler)

    playerImage.validate()
  }

  addNpcToMap(animal) {
    window.map.dataProvider.images.push({
      latitude: animal.latitude,
      longitude: animal.longitude,
      label: '',
      imageURL: 'src/img/animals/' + animal.image,
      width: 36,
      height: 36,
      selectable: true,
      fixedSize: true,
      id: 'w' + animal.id,
      inCountry: animal.inCountry,
      animateAngle: false,
      selectedScale: 1
    })

    Utils.seperateAnimalImage(window.map.getObjectById('w' + animal.id), countries, playerAnimals, worldAnimals)
    window.map.validateData()
  }

  addPlayerToMap(animal) {
    this.removeAnimalFromMap('p' + animal.id)
    window.map.dataProvider.images.push({
      latitude: animal.latitude,
      longitude: animal.longitude,
      label: '',
      imageURL: '/src/img/animals/' + animal.image,
      width: 36,
      height: 36,
      selectable: true,
      fixedSize: true,
      id: 'p' + animal.id,
      inCountry: animal.inCountry,
      animateAngle: false,
      selectedScale: 1.2
    })

    Utils.seperateAnimalImage(window.map.getObjectById('p' + animal.id), countries, playerAnimals, worldAnimals)
    this.bringNpcsForward();
    window.map.validateData()  
  }

  bringNpcsForward() {
    var index = 0;
    for (var mapAnimal of window.map.dataProvider.images) {
      var id = mapAnimal.id.replace('w', '');
      var animalData = Utils.findAnimalById(id, [] , worldAnimals);
      if (!animalData) {
        continue;
      }
      if (animalData.boss) {
        this.removeAnimalFromMap(mapAnimal.id)
        window.map.dataProvider.images.push(mapAnimal)
      }
      index++;
    }
  }

  removeAnimalFromMap(id) {
    for (var i = 0; i < window.map.dataProvider.images.length; i++) {
      if (window.map.dataProvider.images[i].id == id) {
       window. map.dataProvider.images.splice(i, 1)
      }
    }
  }

  displayPlayerAnimals() {
    selectedCard = playerAnimals[0].id;
    for (var animal of playerAnimals) {
      if (!animal.boss) {
        this.addPlayerToMap(animal)
      }
    }
  }

  displayPreFight() {
    $('.pre-fight-menu').show();
    $('.nearby-list').html('');
    $('.fighters').html('');
    $('.stats').html('');
    $('.boss').html(this.createCard(window.gameAnimal));
    for (var animal of playerAnimals) {
      if (animal.boss) {
        continue;
      }
      var d = Utils.distance(
        animal.latitude,
        animal.longitude,
        window.gameAnimal.latitude,
        window.gameAnimal.longitude,
        'K'
      )
      if (d > 5000) {
        continue;
      }
      var card = this.createCard(animal);
      $('.nearby-list').append(card)
    }
  }

  displayBosses() {
    this.removeAnimalFromMap('circle');
    this.removeAnimalFromMap('circle');
    for (var animal of worldAnimals) {
      if (animal.boss) {
        animal.boss = true;
        this.addNpcToMap(animal);
        var mapImage = window.map.getObjectById('w' + animal.id)

        mapImage.width = 72;
        mapImage.height = 72;
        window.map.dataProvider.images.unshift({
          latitude: mapImage.latitude,
          longitude: mapImage.longitude,
          label: '',
          imageURL: 'src/img/circle.svg',
          width: window.map.kilometersToPixels(10000),
          height: window.map.kilometersToPixels(10000),
          alpha: 1,
          selectable: false,
          fixedSize: false,
          id: 'circle',
          bringForwardOnHover: false,
          autoZoom: false,
          mouseEnabled: false,
          rollOverScale: 0,
          selectedScale: 0
        })
      } else {
        this.removeAnimalFromMap('w' + animal.id)
      }
    }

    window.map.validateData()
  }


  async displaySpawnReward(animalData, effectiveness, timestamp, sig, nonce){
    $('.spawn-reward-modal').on('show.bs.modal', async (e) => {
      var modal = $(e.target);
      window.spawnInfo = { animalData, effectiveness, timestamp, sig, nonce };
      modal.find('.modal-title').html('Spawn ' + animalData.name)
      modal.find('.spawn-img').attr('src', 'src/img/animals/' + animalData.image)
      if (animalData.rarity === 0) {
        var cost = 30;
      } else if (animalData.rarity == 1) {
        var cost = 60;
      } else {
        var cost = 90;
      }
      modal.find('.effectiveness').html('Effectiveness: ' + effectiveness);
      modal.find('.gem-cost').html('Gem cost: ' + cost)
      var gems = await Wallet.getGems()
      Wallet.getBalance((balance) => {
        if (cost > gems || balance < 0.011) {
          modal.find('.warning').html(
            'Spawning offspring requires enough gems and eth.'
          );
        }
      });
      modal.find('.eth-cost').html('Eth: ' + Wallet.fromWei(Wallet.spawnCost));
    });

    $('.spawn-reward-modal').modal({
      backdrop: false,
      static: true
    }).modal('show');
  }

  openInventory(key) {
    $('.inventory').html('');
    for (var inventory of window.accessories) {
      var card = this.createInventoryCard(inventory)
      card.data('id', inventory.id);
      $('.inventory').append(card)
    }
    $('.inventory').show()
  }

  createCard(animal) {
    var id = animal.id;
    var card = $('.card-template').clone();
    if (!animal.alpha) {
      var expNeeded = Utils.nextLevelExp(animal.xp + animal.unsyncedXp);
    }
    card.removeClass('card-template');
    card.addClass(id);
    card.attr('data-id', id);
    card.find('.name').html(animal.name);
    card.find('.effectiveness').html(animal.effectiveness);
    var cardNumber = Math.round(animal.effectiveness / 2 / 10);
    card.find('.effectiveness').css('background-image', 'url("src/img/cards/icons/other/' + cardNumber + '.png")')
    card.find('.cardbg').css('background-image', 'url("src/img/cards/' + animal.nature + '/' + cardNumber + '.png")')
    card.find('.card-img').attr('src', 'src/img/animals/' + animal.image);
    if (!animal.alpha) {
      card.find('.level').html('Level ' + Utils.level(animal.xp + animal.unsyncedXp));
    }
    card.find('.attack').html(
      '<div title="' + animal.attack + ' attack"><img class="card-property-icon" ' +
      'src="src/img/cards/icons/stats/attack.png"️></div>'
    );
    card.find('.defense').html(
      '<div title="' + animal.defense + ' defense"><img class="card-property-icon" ' +
      'src="src/img/cards/icons/stats/defense.png"></div>'
    );
    card.find('.accuracy').html(
      '<div title="' + animal.accuracy + ' accuracy"><img class="card-property-icon" ' +
      'src="src/img/cards/icons/stats/accuracy.png"></div> '
    );
    card.find('.evasion').html(
      '<div title="'+ animal.evasion + ' evasion"><img class="card-property-icon" ' +
      'src="src/img/cards/icons/stats/evasion.png"></div>'
    );
    
    if (animal.rarity == 0) {
      card.find('.rarity').html(
        '<img src="src/img/cards/star_' + animal.rarity + '.png" title="Common">'
      );
    } else if (animal.rarity == 1) {
      card.find('.rarity').html(
        '<img src="src/img/cards/star_' + animal.rarity + '.png" title="Uncommon">'
      );
    }else if (animal.rarity == 2) {
      card.find('.rarity').html(
        '<img src="src/img/cards/star_' + animal.rarity + '.png" title="Rare">'
      );
    }
    if (!animal.alpha) {
      /*
      if (animal.unsyncedXp && animal.unsyncedXp.constructor !== Object) {
        card.find('.unsyncedXpBtn').show();
        card.find('.unsyncedXp').html('XP<br>' + animal.unsyncedXp).show()
      } else {
        card.find('.unsyncedXpBtn').hide();
        card.find('.unsyncedXp').hide();
        
      }
      */
    }
    card.find('.attack-text').html(animal.attack);
    card.find('.defense-text').html(animal.defense);
    card.find('.accuracy-text').html(animal.accuracy);
    card.find('.evasion-text').html(animal.evasion);
    card.find('.health-text').html(animal.health);
    if (!animal.alpha) {
      card.find('.experience > .progress-bar').width(
        (animal.xp + animal.unsyncedXp) / expNeeded * 188
      );
       card.find('.experience > .progress-bar').attr(
        'title',
        'XP ' + (animal.xp + animal.unsyncedXp) + '/' + expNeeded
      ); 
    }
    var attackSkillTitle = SkillUtils.getAttackSkillTitle(animal.attackSkill);    
    var defenseSkillTitle = SkillUtils.getSupportSkillTitle(animal.defenseSkill);
    card.find(
      '.attack-skill').html('<img src="src/img/cards/icons/attacks/' + animal.attackSkill +
      '.png" title="' + attackSkillTitle + '">'
    )
    card.find(
      '.defense-skill').html('<img src="src/img/cards/icons/defense/' + animal.defenseSkill +
      '.png" title="' + defenseSkillTitle + '">'
    )
    if (animal.accessories && animal.accessories[0]) {
      for (var accessory of accessoryData) {
        if (accessory.id == animal.accessories[0]) {
          card.find('.accessory1 img').attr('src', 'src/img/cards/accessories/' + accessory.image)
        }
      }
    }
    if (animal.currentPrice) {
      card.find('.buy-btn').html('Buy Ξ ' + Wallet.fromWei(animal.currentPrice.toString()).slice(0, 8));
    }
    if (animal.currentValue) {
      card.find('.buy-btn').html('Buy Ξ ' + Wallet.fromWei(animal.currentValue.toString()).slice(0, 8));
    }
    card.find('.sell-btn').html('Sell');
    card.find('.cancel-sell-btn').html('Cancel')
    card.show()
    if (!animal.alpha) {
      card.on('click', (e) => {
        if($(e.target).hasClass('accessory1')) {
          this.openInventory('accessory1');
          e.preventDefault();
        }

        if($(e.target).hasClass('syncXpImg')) {
          this.openSyncWarning(() => {
            socket.emit('syncXp', animal.id)
            e.preventDefault();
          });
        }
      });
    } else {
      card.find('.alpha').show();
    }
    

    return card
  }

  createDnaCard(id, contractData) {
    var card = $('.dna-card-template').clone();
    card.removeClass('dna-card-template');
    card.addClass(id.toString());
    card.data('id', id.toString());
    card.data('contractData', contractData);
    card.find('.name').html(contractData.name);
    card.find('.effectiveness').html(contractData.effectiveness);
    var dna = Utils.findDnaById(id.toString(), playerDna)
    card.find('.card-img').attr('src', 'src/img/animals/' + dna.image);
    card.show();
    return card;
  }

  createInventoryCard(item) {
    var card = $('.inventory-card-template').clone();
    card.removeClass('inventory-card-template');
    card.addClass(item.name);
    card.data('name', item.name);
    var nameTitle = Utils.itemNameToTitle(item.name);
    card.find('.name').html(nameTitle);
    card.find('.image').attr('src', 'src/img/cards/accessories/' + item.image)
    card.find('.description').html(item.description)
    card.show()
    return card
  }

  updateCard(id) {
    var animalId = id.toString().replace('p', '').replace('w', '');
    var card = $('[data-id="' + animalId + '"]')
    var animal = Utils.findAnimalById(animalId, playerAnimals, worldAnimals)

    if(animal.alpha) {
      card.find('.alpha').show();
    } else {
      card.find('.alpha').hide();
      if (animal.unsyncedXp && animal.unsyncedXp.constructor !== Object) {
        card.find('.unsyncedXpBtn').show();
        var xpToShow = animal.unsyncedXp
        if (30 <= xpToShow) {
          xpToShow = "30+";
        }
        card.find('.unsyncedXp').html('XP<br>' + xpToShow).show()
      } else {
        card.find('.unsyncedXpBtn').hide();
        card.find('.unsyncedXp').hide();
      }
    }

    if(animal.accessory1) {
      var acc1 = card.find('.accessory1');
      var acc1Img = acc1.find('img')
      if (acc1Img.attr('src') == undefined) {
        acc1Img.attr('src', 'src/img/cards/accessories/' +animal.accessory1  + '.png')
      }
    }

    if (animal.fightingCooldown > Math.floor(new Date().getTime() / 1000)) {
      card.find('.cooldowns').show();
      card.find('.fighting').show();
      card.find('.traveling').hide();
      var s = animal.fightingCooldown - Math.floor(new Date().getTime() / 1000);
      card.find('.cooldowns').find('.meter').height(s / 4)
      card.find('.text').html(s + 's')
      if (s <= 0) {
        card.find('.cooldowns').hide();
      }
    } else if (animal.travelingCooldown > Math.floor(new Date().getTime() / 1000)) {
      card.find('.cooldowns').show();
      card.find('.fighting').hide();
      card.find('.traveling').show();
      var s = animal.travelingCooldown - Math.floor(new Date().getTime() / 1000);
      card.find('.cooldowns').find('.meter').height(s / 7)
      card.find('.text').html(s + 's')
      if (s <= 0) {
        card.find('.cooldowns').hide();
      }
    } else {
      card.find('.cooldowns').hide();
    }
  }

  updateCardExp(animal) {
    var card = $('.' + animal.id)
    var expNeeded = Utils.nextLevelExp(animal.xp + animal.unsyncedXp)

    if (Utils.level(animal.xp) != Utils.level(animal.xp + 1) ) {

    }
    animal.xp++

    card.find('.experience > .progress-bar').width((animal.xp + animal.unsyncedXp) / expNeeded * 188);
    card.find('.experience > .progress-bar').attr(
        'title',
        'XP ' + (animal.xp + animal.unsyncedXp) + '/' + expNeeded
      ); 
    card.find('.plus-one').show().animate({ top: '100px', visability: 0 }, 1500, function() {
      card.find('.plus-one').hide().css({ top: '190px', visability: 1 })
    })
  }

  openSellMenu(id) {
    var animal = Utils.findAnimalById(id, playerAnimals, worldAnimals);
    $('.sell-modal').on('show.bs.modal', (e) => {
      var modal = $(e.target);
      modal.find('.modal-title').html('Sell ' + animal.name);
      modal.find('.modal-body').html("<iframe src='https://opensea.io/assets/0x14c4293d7e7325cec8c52cea3df37d91aa9cc7b6/" + id.toString() +"?embed=true' width='100%' height='500px' frameborder='0' allowfullscreen></iframe>");
    });

    $('.sell-modal').modal({
      backdrop: true
    }).modal('show');
  }

  openSyncWarning(callback) {
    $('.sync-warning-modal').on('hide.bs.modal', (e) => {
      var modal = $(e.target);
      modal.find('.sync-confirm').unbind('click');
    });

    $('.sync-warning-modal').find('.sync-confirm').click(() => {
      $('.sync-warning-modal').modal('hide');
      callback()
    })

    $('.sync-warning-modal').modal({
      backdrop: true
    }).modal('show');
  }



  async sequence(dnaIds) {
    Wallet.sequenceDNA(dnaIds, () => {
      console.log('Start dna sequence ' + dnaIds)
      window.dnaGame.scene.keys.dna.sequenceAnimation();
    },
    async () => {
      var animal = Utils.findDnaById(dnaIds[0], playerDna);
      this.rebuildDnaDeck();
      if (!muted) {
        this.sound.play('fireworksSo');
        this.sound.play('jingleSo');
      }
      window.dnaGame.scene.keys.dna.displayReward(animal);
      window.socket.emit('playerAnimals');
      await Wallet.getPlayerDna();
      
      console.log('Show sequence animation output')
    });
  }

  displayRewardMenu(title, content) {
    $('.reward-modal').on('show.bs.modal', (e) => {
      var modal = $(e.target)
      modal.find('.modal-title').html(title)
      modal.find('.modal-body').html(content)
    });

    $('.reward-modal').modal({
      backdrop: true
    }).modal('show');
  }

  loadAnimalImages() {
    for (var animal of window.animalData.animals) {
      this.load.atlas( // Animals
        animal.image,
        'src/img/atlas/animals/' + animal.image + '.png',
        'src/img/atlas/animals/' + animal.image + '.json'
      );
    }
  }
}
