/*
* Index is the start of Phaser. The chain goes like index, preloader, map, game. 
*/

import 'phaser';
import pkg from 'phaser/package.json';
import 'fight';
import Preloader from 'preloader';
import Fight from 'fight';
import MapSetup from 'map_setup';
import Game from 'game';
import Dna from 'dna';

const width = 1920;
const height = 1080;
window.width = width;
window.height = height;
var onTop = true;

const config = {
  width,
  height,
  transparent: true,
  parent: 'phaser',
  type: Phaser.CANVAS,
  scene: [{ preload, create}, Fight, Preloader, MapSetup, Game ],
  autoCenter: 1,
  scaleMode: 3,
  disableWebAudio: true
};

const game = new Phaser.Game(config);
window.game = game;

function preload() {
  this.load.image('blackMask', 'src/img/fight/black_mask.png');
  this.load.image('preloaderBar', 'src/img/preloader/loading_bar_1.png');
  this.load.image('preloaderBg', 'src/img/preloader/loading_bar_2.png');
  this.load.atlas('earth', 'src/img/preloader/loading_earth.png', 'src/img/preloader/loading_earth.json');
}

async function create() {
  this.scene.start('preloader');
}


window.dnaGame = new Phaser.Game({
  width: '100%',
  height: '100%',
  transparent: true,
  parent: 'machine',
  type: Phaser.CANVAS,
  scene: [ Dna ],
  autoCenter: 1,
  scaleMode: 2,
  disableWebAudio: true
});
dnaGame.scene.start('dna')